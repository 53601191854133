import { createSlice } from "@reduxjs/toolkit";

import {
  // setListingState,
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  commonPaginationForPost,
  denialsHeatmapGroupByEnum,
  exceptionArray,
  getAscArray,
  getCurrentClientId,
  getDataForLineChart,
  getExludedReasonCode,
  getGroupByCodeDataReasonCodeDepartment,
  getGroupByHeatMapDataCARC,
  getGroupByHeatMapDataCARCCategory,
  getGroupByHeatMapDataCARCDepartment,
  getGroupByHeatMapDataCPT,
  getGroupByHeatMapDataCPTDepartment,
  getMultiSelectFilterValue,
  // getTreeNodeObj,
  getTreeNodeObjFullPath,
  getTreeNodeObjInsights,
  numberFormatIntoShortForm,
  trimReasonCodes,
} from "../../../components/common/Utils/utils";
import { getMultiSelectFilterValueForApi } from "./../../../components/common/Utils/utils";
import genericAxiosCall from "./../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "./../../../constants/serverRoutes";
import { gettingSmartFilterForOncologyDenial_Call } from "../OncologyDashboard/OncologyDashboardKpisSlice";
import { ClaimAdjGroupCodeMapping } from "../../../components/common/Utils/claimAdjGroupCode";
import store from "../../store";
import { gettingClientsConfigListForDropdown } from "../ClientsConfig/ClientsConfigSlice";
import { gettingAdvSettingData_Call } from "../Settings/advanceSettingSlice";
// import store from "../../Store";

let constDate = null;

// carcHandleYearOverYear
const carcHandleYearOverYear = (data) => {
  let resData = data.oncologyCARCMonthByMonth;
  try {
    if (constDate === "YOY") {
      let allDate = {};
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // data.oncologyCARCYearByYear
      const uniqueYear = [...new Set(resData.map((item) => item.year))];

      for (let i = 0; i < uniqueYear.length; i++) {
        let arr = [];
        let year = uniqueYear[i];

        resData.map((item, index) => {
          if (item?.year === year) {
            arr.push(item);
          }
        });

        allDate[`${year}`] = arr;
      }

      let newObj = {};
      for (let i = 0; i < uniqueYear.length; i++) {
        let newArr = [];
        let j = 0;
        let year = uniqueYear[i];
        let tempArr = allDate[`${year}`];
        monthNames.map((month, index) => {
          if (j < tempArr?.length) {
            if (month === tempArr[j]?.monthName && j < tempArr.length) {
              newArr.push(tempArr[j]);
              j++;
            } else {
              let t = {
                monthName: month,
                year: uniqueYear[i],
                totalAmount: 0,
                denialAmount: 0,
                denialCount: 0,
              };
              newArr.push(t);
            }
          } else {
            let t = {
              monthName: month,
              year: uniqueYear[i],
              totalAmount: 0,
              denialAmount: 0,
              denialCount: 0,
            };
            newArr.push(t);
          }
        });
        newObj[`${year}`] = newArr;
      }

      //update from here
      allDate = newObj;
      return { allDate, uniqueYear };
    } else {
      return false;
    }
  } catch (e) {
    // console.log("e--->>", e);

    return false;
  }
};

const getMultiLineWeekChartData = (data, weekFilter) => {
  let resData = data;

  try {
    const reasonCodes = [
      ...new Set(resData.map((item) => item.groupReasonCode)),
    ];
    const convertedData = reasonCodes.map((reasonCode) => {
      const items = resData.filter(
        (item) => item.groupReasonCode === reasonCode
      );
      const weeksWithData = [...new Set(items.map((item) => item[weekFilter]))];

      const newData = weeksWithData.map((week, i) => {
        const existingItem = items.find((item) => item[weekFilter] === week);

        if (existingItem) {
          return {
            billedAmount: existingItem.totalAmount,
            denialCount: existingItem.denialCount,
            description: existingItem.description,
            x: `Week-${1 + i}`,
            y: existingItem.denialAmount,
            year: 2019,
          };
        } else {
          return {
            billedAmount: 0,
            denialCount: 0,
            description: "",
            x: `Week-${1 + i}`,
            y: 0,
            year: 2019,
          };
        }
      });

      // console.log("newData--->>", reasonCode, newData);
      return {
        color: "hsl(116, 70%, 50%)",
        data: newData,
        // id: `Reason Code - ${reasonCode}`,
        id: `${reasonCode}`,
      };
    });
    // console.log("convertedData--->>", convertedData);
    return convertedData;
  } catch (e) {
    // console.log("e--->>", e);
    return false;
  }
};

const getMultiLineChartData = (data) => {
  let resData = data;
  try {
    let allDate = {};
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const uniqueYear = [
      ...new Set(resData.map((item) => item.groupReasonCode)),
    ];
    const uniqueMonth = [...new Set(resData.map((item) => item.month))]; // dateRange -> month

    for (let i = 0; i < uniqueYear.length; i++) {
      let arr = [];
      let year = uniqueYear[i];

      resData.map((item, index) => {
        if (item?.groupReasonCode === year) {
          arr.push(item);
        }
      });

      allDate[`${year}`] = arr;
    }

    let newObj = {};
    for (let i = 0; i < uniqueYear.length; i++) {
      let newArr = [];
      let j = 0;
      let year = uniqueYear[i];
      let tempArr = allDate[`${year}`];
      (resData[0]?.filterType === "Q" ? uniqueMonth : monthNames).map(
        (month, index) => {
          if (j < tempArr?.length) {
            if (month === tempArr[j]?.month && j < tempArr.length) {
              // newArr.push(tempArr[j]);
              newArr.push({
                monthName: tempArr[j]?.month,
                reasoncode: tempArr[j]?.groupReasonCode,
                totalAmount: tempArr[j]?.totalAmount,
                denialAmount: tempArr[j]?.denialAmount,
                denialCount: tempArr[j]?.denialCount,
                description: tempArr[j]?.description,
              });
              j++;
            } else {
              let t = {
                monthName: month,
                reasoncode: uniqueYear[i],
                totalAmount: 0,
                denialAmount: 0,
                denialCount: 0,
                description: "",
              };
              newArr.push(t);
            }
          } else {
            let t = {
              monthName: month,
              reasoncode: uniqueYear[i],
              totalAmount: 0,
              denialAmount: 0,
              denialCount: 0,
              description: "",
            };
            newArr.push(t);
          }
        }
      );
      newObj[`${year}`] = newArr;
      // console.log("newObj[`${year}`]--->>", newArr);
    }

    //update from here
    allDate = newObj;
    return { allDate, uniqueReasonCode: uniqueYear };
  } catch (e) {
    // console.log("e--->>", e);

    return false;
  }
};

export const OncologyCARCSlice = createSlice({
  name: "OncologyCARCSlice",
  initialState: {
    //Filter Array
    carcReasonCodeFilterArr: [],
    carcReasonCode2FilterArr: [],
    carcGroupReasonCodeFilterArr: [],
    cardDepartmentArrReasonCode: [],
    carcServiceLineArr: [],
    carcCategoryFilterArr: [],
    carcTrendsLineChartArr: [],
    payerFilterArr: [],
    carcDetail: {
      DenialPer: null,
      DenialCount: null,
      DenialAmount: null,
      ChargeAmount: null,
      tabularDataArr: [],
    },
    carcReasonCodeCards: [],
    carcTrendsGraphArr: [],
    totalCARCTableData: {
      denialAmount: 0,
      chargeAmount: 0,
      denialCount: 0,
    },
    // resonCode Dash KPI's graph data
    reasonCodeDashPayerFilterArr: [],
    reasonCodeDashGroupReasonCodeFilterArr: [],
    reasonCodeDashGraphArr: [],
    reasonCodeDashFilterArr: [],

    // ReasonCodeHeatMap here
    insightsReasonCodeHeatMapArr: {},
    insightsRemarkCodeHeatMapArr: {},

    insightsDenialbyCPTCodeHeatMapArr: {},
    insightsReasonCodeHeatMapArrMissedOpp: {},
    insightsDenialbyCPTCodeMissedOppHeatMapArr: {},
    rangesArray: {
      CPTCodeHeatMapArr: "",
      ReasonCodeHeatMapArr: "",
      CPTCodeHeatMapArrMissedOpp: "",
      ReasonCodeHeatMapArrMissedOpp: "",
      RemarkCode: "",
    },
    // DepartmentsReasonCodeHeatMap here
    departmentsReasonCodeHeatMapArr: {},
    departmentsRemarkCodeHeatMapArr: {},

    // Dep CARC
    depCarcTableLoader: true,
    depCarcTableData: [],
    depCarcTreeMapLoader: true,
    depCarcTreeMapData: {},

    // Dep CPT
    depCptTableLoader: true,
    depCptTableData: [],
    depCptTreeMapLoader: true,
    depCptTreeMapData: [],

    // Dep RARC
    depRarcTableLoader: true,
    depRarcTableData: [],
    depRarcTreeMapLoader: true,
    depRarcTreeMapData: [],

    departmentsDenialbyCPTCodeHeatMapArr: {},
    departmentsReasonCodeHeatMapArrMissedOpp: {},
    departmentsDenialbyCPTCodeMissedOppHeatMapArr: {},
    rangesDepartmentsArray: {
      CPTCodeHeatMapArr: "",
      ReasonCodeHeatMapArr: "",
      CPTCodeHeatMapArrMissedOpp: "",
      ReasonCodeHeatMapArrMissedOpp: "",
      RemarkCode: "",
    },
    //loader
    groupByAllLoader: false,
    groupByDepartmentLoader: false,
    groupByMissedOppLoader: false,
    groupByCodeLoader: false,
    groupByCategoryLoader: false,
    groupByRemarkCodeLoader: false,

    //setters
    groupByDepartmentCPT: [],
    groupByDepartmentCARC: [],
    groupByCodeCARC: [],
    groupByCategoryCARC: [],
    groupByCodeCARC: [],
    departmentArr: [],
    departmentArrReasonCode: [],

    availableFacilitiesList: [],
    cptCodeList: [],
    availableHospitalsList: [],
    insightsSmartFilterArr: [],
    claimStatus23FilterArr: [],
    claimStatusCodeIdsSelected: [],
    ReasonCodeBarChart: {
      softAdjustmentsBarChart: [],
      hardAdjustmentsBarChart: [],
      keyValues: [],
    },
    reasonCodeCategoryBarChart: [],

    //carc graphs
    scatterGraphAllData: [],
    scatterGraphAllReChartData: [],
    bubbleChartData: [],
  },
  reducers: {
    setCARCFilters_Data: (state, action) => {
      let data = action.payload;

      // for  reasonCode filter Arr
      if (data?.carcReasonCodeFilter?.length > 0) {
        let newArr2 = [];
        data?.carcReasonCodeFilter?.forEach((item) => {
          let reasonCode_and_desc = `${item?.reasonCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;

          newArr2.push({
            label: reasonCode_and_desc,
            value: item?.reasonCode,
          });
        });

        state.carcReasonCode2FilterArr = newArr2;
      } else {
        state.carcReasonCode2FilterArr = [];
      }

      // for handle groupCode filter Arr

      if (data?.groupCodeFilter?.length > 0) {
        let newArr2 = [];
        data?.groupCodeFilter?.forEach((item) => {
          let desc = ClaimAdjGroupCodeMapping(item?.groupCode);
          newArr2.push({
            label: `${item?.groupCode} - ${desc}`,
            value: item?.groupCode,
          });
        });

        state.carcReasonCodeFilterArr = newArr2;
      } else {
        state.carcReasonCodeFilterArr = [];
      }

      // for  category filter Arr
      if (data?.rcCategoryFilter?.length > 0) {
        let newTempArr = [];
        data?.rcCategoryFilter?.forEach((item) => {
          let category_and_desc = `${item?.categoyName}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newTempArr.push({
            label: category_and_desc,
            value: item?.categoyName,
          });
        });

        state.carcCategoryFilterArr = newTempArr;
      } else {
        state.carcCategoryFilterArr = [];
      }

      if (data?.cptCodeJSON?.length > 0) {
        state.carcServiceLineArr = JSON.parse(data?.cptCodeJSON);
        // var newArr = [];
        // data?.departments.forEach((item, index) => {
        //   newArr.push(item?.name);
        // });
        // newArr.unshift("Departments(All)");
        // state.carcServiceLineArr = newArr;
      } else {
        state.carcServiceLineArr = [];
      }
    },
    //Loader true
    successFilters: (state, action) => {
      const data = action?.payload;
      if (data?.departments?.length > 0) {
        state.departmentArr = JSON.parse(data?.departments);
        // var newArr = [];
        // data?.departments.forEach((item, index) => {
        //   newArr.push(item?.name);
        // });
        // newArr.unshift("Departments(All)");
        // state.departmentArr = newArr;
      } else {
        state.departmentArr = [];
      }
      if (data?.facilities?.length > 0) {
        let newArr = [];

        data?.facilities.splice(
          data?.facilities.findIndex((a) => a?.name === ""),
          1
        );
        data?.facilities?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.name,
          });
        });

        state.availableFacilitiesList = newArr;
      } else {
        state.availableFacilitiesList = [];
      }
      //Payer Filter
      if (data?.payers?.length) {
        let newArr = [];
        data?.payers?.map((item, index) => {
          newArr.push({
            label: `${item?.payerName}-${item?.payerPrimaryId}`,
            value: item?.payerId,
          });
        });
        state.payerFilterArr = newArr;
      } else {
        state.payerFilterArr = [];
      }
      //CPT Code List
      if (data?.cptCodes?.length > 0) {
        cptCodeListInitial(state, action);
      } else {
        state.cptCodeList = [];
      }
      if (data?.hospitals?.length > 0) {
        let newArr = [];
        data?.hospitals?.forEach((item) => {
          if (item?.displayName && item?.healthSystemId) {
            newArr.push({
              label: item?.displayName,
              value: item?.healthSystemId,
              fullName: item?.hospital,
            });
          }
        });
        state.availableHospitalsList = newArr;
      } else {
        state.availableHospitalsList = [];
      }
    },
    //Group By All
    gettingGroupByAll: (state, action) => {
      state.groupByAllLoader = true;
    },
    successGroupByAll: (state, action) => {
      state.groupByAllLoader = false;

      const data = action?.payload;
      if (data?.denialByReasonCodeHeatmap?.length > 0) {
        let newArr = [];
        let countValue = 0;
        const finalData = data?.denialByReasonCodeHeatmap;

        const highest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );
        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.ReasonCodeHeatMapArr = range;
        finalData?.map((item, index) => {
          newArr.push({
            name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
              item?.denielAmount
            )}`,
            code: item?.reasonCode,
            actualValue: item?.denielAmount,

            value:
              item?.denielAmount < 0
                ? item?.denielAmount * -1
                : item?.denielAmount,
            ranges: { range: range, denialAmount: item?.denielAmount },

            chargeAmount: item?.chargeAmount,
            denial23Amount: item?.deniel23Amount,
            denial23Count: item?.deniel23Count,
            denialPercentage: item?.denialPercentage,
            denielCount: item?.denielCount,
            description: item?.description,
            totalCount: item?.totalCount,
          });
          countValue = countValue + item?.denielAmount;
        });

        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };
        state.insightsReasonCodeHeatMapArr = obj;
      } else {
        state.insightsReasonCodeHeatMapArr = {};
        state.rangesArray.ReasonCodeHeatMapArr = "";
      }
      // handle denialbyCPTCodeHeatmap here
      if (data?.denialbyCPTCodeHeatmap?.length > 0) {
        let newArr = [];
        let countValue = 0;
        const finalData = data?.denialbyCPTCodeHeatmap;
        const highest = data?.denialbyCPTCodeHeatmap?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = data?.denialbyCPTCodeHeatmap?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );

        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.CPTCodeHeatMapArr = range;

        // const { countValue, newArr, groupByDeptObj, finalArr } =
        //   getGroupByHeatMapDataCPT(finalData, range);

        // // let objGroupByDept = {
        // //   name: "root",
        // //   children: [
        // //     {
        // //       brand: "All",
        // //       children: finalArr,
        // //       name: "All",
        // //     },
        // //   ],
        // // };
        // state.groupByDepartmentCPT = finalArr;
        finalData?.map((item, index) => {
          newArr.push({
            // name: item?.cptCode,
            name: `${item?.cptCode} - $${numberFormatIntoShortForm(
              item?.denielAmount
            )}`,
            code: item?.cptCode,
            actualValue: item?.denielAmount,
            value:
              item?.denielAmount < 0
                ? item?.denielAmount * -1
                : item?.denielAmount,

            ranges: { range: range, denialAmount: item?.denielAmount },
            chargeAmount: item?.chargeAmount,
            denial23Amount: item?.deniel23Amount,
            denial23Count: item?.denielCount,
            denialPercentage: item?.denialPercentage,
            denielCount: item?.denielCount,
            description: item?.description,
            totalCount: item?.totalCount,
          });
        });

        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };

        state.insightsDenialbyCPTCodeHeatMapArr = obj;
      } else {
        state.insightsDenialbyCPTCodeHeatMapArr = {};
        state.rangesArray.CPTCodeHeatMapArr = "";
      }
      // if (data?.departments?.length > 0) {
      //   state.departmentArr = JSON.parse(data?.departments);
      //   // var newArr = [];
      //   // data?.departments.forEach((item, index) => {
      //   //   newArr.push(item?.name);
      //   // });
      //   // newArr.unshift("Departments(All)");
      //   // state.departmentArr = newArr;
      // } else {
      //   state.departmentArr = [];
      // }
      // if (data?.facilities?.length > 0) {
      //   let newArr = [];

      //   data?.facilities.splice(
      //     data?.facilities.findIndex((a) => a?.name === ""),
      //     1
      //   );
      //   data?.facilities?.forEach((item) => {
      //     newArr.push({
      //       label: item?.name,
      //       value: item?.name,
      //     });
      //   });

      //   state.availableFacilitiesList = newArr;
      // } else {
      //   state.availableFacilitiesList = [];
      // }
      // //CPT Code List
      // if (data?.cptCodeList?.length > 0) {
      //   let newArr = [];

      //   data?.cptCodeList.splice(
      //     data?.cptCodeList.findIndex((a) => a?.name === ""),
      //     1
      //   );
      //   data?.cptCodeList?.forEach((item) => {
      //     const label = item?.cptCodeDescription
      //       ? item?.cptCode + " - " + item?.cptCodeDescription
      //       : item?.cptCode;
      //     newArr.push({
      //       label: label,
      //       value: item?.cptCode,
      //     });
      //   });
      //   state.cptCodeList = newArr;
      // } else {
      //   state.cptCodeList = [];
      // }
      // if (data?.hospitals?.length > 0) {
      //   let newArr = [];
      //   data?.hospitals?.forEach((item) => {
      //     if (item?.displayName && item?.payeeId) {
      //       newArr.push({
      //         label: item?.displayName,
      //         value: item?.payeeId,
      //       });
      //     }
      //   });
      //   state.availableHospitalsList = newArr;
      // } else {
      //   state.availableHospitalsList = [];
      // }
    },

    failedGroupByAll: (state, action) => {
      state.groupByAllLoader = false;
    },
    //Group By Category
    gettingGroupByCategory: (state, action) => {
      state.groupByCategoryLoader = true;
    },
    successGroupByCategory: (state, action) => {
      const data = action.payload;
      state.groupByCategoryLoader = false;
      if (data?.denialByReasonCodeHeatmap?.length > 0) {
        const finalData = data?.denialByReasonCodeHeatmap;

        const {
          countValue,
          newArr,
          groupByDeptObj,
          finalArrByDept,
          finalArrByCategory,
        } = getGroupByHeatMapDataCARCCategory(finalData);
        // state.groupByDepartmentCARC = finalArrByDept;
        state.groupByCategoryCARC = finalArrByCategory;
        // let obj = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: newArr,
        //       name: "All",
        //       value: countValue,
        //     },
        //   ],
        // };

        // state.insightsReasonCodeHeatMapArr = obj;
      } else {
        // state.groupByDepartmentCARC = [];
        state.groupByCategoryCARC = [];
        // state.insightsReasonCodeHeatMapArr = {};
      }
    },
    failedGroupByCategory: (state, action) => {
      state.groupByCategoryLoader = false;
    },
    //Group By RemarkCode
    gettingGroupByRemarkCode: (state, action) => {
      state.groupByRemarkCodeLoader = true;
    },
    successGroupByRemarkCode: (state, action) => {
      state.groupByRemarkCodeLoader = false;
      const data = action?.payload;
      if (data?.denialByRemarkCodeHeatmap?.length > 0) {
        let newArr = [];
        let countValue = 0;
        const finalData = data?.denialByRemarkCodeHeatmap;

        // const highest = finalData?.reduce(
        //   (prev, cur) => (prev?.amount > cur?.amount ? prev : cur),
        //   null
        // );
        const highest = finalData?.reduce(
          (prev, cur) =>
            Number(prev?.amount) > Number(cur?.amount) ? prev : cur,
          null
        );
        const lowest = finalData?.reduce(
          (prev, cur) =>
            Number(prev?.amount) < Number(cur?.amount) ? prev : cur,
          null
        );

        const range = (highest?.amount - lowest?.amount) / 4;
        state.rangesArray.RemarkCode = range;
        finalData?.map((item, index) => {
          newArr.push({
            name: `${
              item?.claimPaymentRemarkCodes
                ? item?.claimPaymentRemarkCodes
                : "Other"
            } - $${numberFormatIntoShortForm(item?.amount)}`,
            code: item?.claimPaymentRemarkCodes,
            actualValue: item?.amount,

            value: item?.amount < 0 ? item?.amount * -1 : item?.amount,
            ranges: { range: range, denialAmount: item?.amount },

            chargeAmount: item?.chargeAmount,
            denial23Amount: item?.deniel23Amount,
            denial23Count: item?.deniel23Count,
            denialPercentage: item?.denialPercentage,
            denielCount: item?.remarkCodeCount,
            description: item?.claimPaymentRemarkCodesDesc,
            totalCount: item?.totalCount,
          });
          countValue = countValue + item?.amount;
        });

        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };
        state.insightsRemarkCodeHeatMapArr = obj;
      } else {
        state.insightsRemarkCodeHeatMapArr = {};
      }
    },
    failedGroupByRemarkCode: (state, action) => {
      state.groupByRemarkCodeLoader = false;
    },
    //Group by GroupCode
    gettingGroupByCode: (state, action) => {
      state.groupByCodeLoader = true;
    },
    successGroupByCode: (state, action) => {
      const data = action.payload;
      state.groupByCodeLoader = false;
      if (data?.denialByReasonCodeHeatmap?.length > 0) {
        const finalDataReasonCode = data?.denialByReasonCodeHeatmap;

        const { finalArr } =
          getGroupByCodeDataReasonCodeDepartment(finalDataReasonCode);

        // let objGroupByDept = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: finalArr,
        //       name: "All",
        //     },
        //   ],
        // };

        state.groupByCodeCARC = finalArr;
      } else {
        state.groupByCodeCARC = [];
      }
    },

    failedGroupByCode: (state, action) => {
      state.groupByCodeLoader = false;
    },
    //Group By Department
    gettingGroupByDepartment: (state, action) => {
      state.groupByDepartmentLoader = true;
    },
    successGroupByDepartment: (state, action) => {
      const data = action.payload;
      state.groupByDepartmentLoader = false;
      if (data?.denialbyCPTCodeHeatmap?.length > 0) {
        const finalDataCPT = data?.denialbyCPTCodeHeatmap;
        const finalDataReasonCode = data?.denialByReasonCodeHeatmap;
        //CPT
        // const highest = data?.denialbyCPTCodeHeatmap?.reduce(
        //   (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
        //   null
        // );
        // const lowest = data?.denialbyCPTCodeHeatmap?.reduce(
        //   (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
        //   null
        // );
        //REASON CODE
        // const highestRC = data?.denialByReasonCodeHeatmap?.reduce(
        //   (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
        //   null
        // );
        // const lowestRC = data?.denialByReasonCodeHeatmap?.reduce(
        //   (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
        //   null
        // );

        // const range = (highestRC?.denielAmount - lowestRC?.denielAmount) / 4;
        // state.rangesArray.CPTCodeHeatMapArr = range;
        // const rangeRC = (highestRC?.denielAmount - lowestRC?.denielAmount) / 4;
        // state.rangesArray.ReasonCodeHeatMapArr = rangeRC;

        const { countValue, newArr, groupByDeptObj, finalArr } =
          getGroupByHeatMapDataCPTDepartment(finalDataCPT);

        const { finalArrByDept } =
          getGroupByHeatMapDataCARCDepartment(finalDataReasonCode);

        // let objGroupByDept = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: finalArr,
        //       name: "All",
        //     },
        //   ],
        // };
        state.groupByDepartmentCPT = finalArr;
        state.groupByDepartmentCARC = finalArrByDept;
      } else {
        state.groupByDepartmentCPT = [];
        state.groupByDepartmentCARC = [];
        state.rangesArray.CPTCodeHeatMapArr = [];
      }
    },

    failedGroupByDepartment: (state, action) => {
      state.groupByDepartmentLoader = false;
    },
    //Missed Opportunity
    gettingGroupByMissedOpp: (state, action) => {
      state.groupByMissedOppLoader = true;
    },
    successGroupByMissedOpp: (state, action) => {
      const data = action.payload;
      state.groupByMissedOppLoader = false;

      if (data?.denialByReasonCodeHeatmap?.length > 0) {
        let newArr = [];
        let countValue = 0;
        const finalData = data?.denialByReasonCodeHeatmap;

        const highest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );
        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;

        state.rangesArray.ReasonCodeHeatMapArrMissedOpp = range;
        finalData?.map((item, index) => {
          newArr.push({
            name: `${item?.reasonCode} - $${numberFormatIntoShortForm(
              item?.denielAmount
            )}`,
            code: item?.reasonCode,
            actualValue: item?.denielAmount,

            value:
              item?.denielAmount < 0
                ? item?.denielAmount * -1
                : item?.denielAmount,
            ranges: { range: range, denialAmount: item?.denielAmount },

            chargeAmount: item?.chargeAmount,
            denial23Amount: item?.deniel23Amount,
            denial23Count: item?.deniel23Count,
            denialPercentage: item?.denialPercentage,
            denielCount: item?.denielCount,
            description: item?.description,
            totalCount: item?.totalCount,
          });
          countValue = countValue + item?.denielAmount;
        });
        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };

        state.insightsReasonCodeHeatMapArrMissedOpp = obj;
      } else {
        state.insightsReasonCodeHeatMapArrMissedOpp = {};
        state.rangesArray.ReasonCodeHeatMapArrMissedOpp = "";
      }
      // handle denialbyCPTCodeHeatmap here
      if (data?.denialbyCPTCodeHeatmap?.length > 0) {
        let newArr = [];
        let countValue = 0;
        const finalData = data?.denialbyCPTCodeHeatmap;
        const highest = data?.denialbyCPTCodeHeatmap?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = data?.denialbyCPTCodeHeatmap?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );

        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.CPTCodeHeatMapArrMissedOpp = range;

        // const { countValue, newArr, groupByDeptObj, finalArr } =
        //   getGroupByHeatMapDataCPT(finalData, range);

        // // let objGroupByDept = {
        // //   name: "root",
        // //   children: [
        // //     {
        // //       brand: "All",
        // //       children: finalArr,
        // //       name: "All",
        // //     },
        // //   ],
        // // };
        // state.groupByDepartmentCPT = finalArr;
        finalData?.map((item, index) => {
          newArr.push({
            // name: item?.cptCode,
            name: `${item?.cptCode} - $${numberFormatIntoShortForm(
              item?.denielAmount
            )}`,
            code: item?.cptCode,
            actualValue: item?.denielAmount,
            value:
              item?.denielAmount < 0
                ? item?.denielAmount * -1
                : item?.denielAmount,

            ranges: { range: range, denialAmount: item?.denielAmount },
            chargeAmount: item?.chargeAmount,
            denial23Amount: item?.deniel23Amount,
            denial23Count: item?.denielCount,
            denialPercentage: item?.denialPercentage,
            denielCount: item?.denielCount,
            description: item?.description,
            totalCount: item?.totalCount,
          });
        });

        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };

        state.insightsDenialbyCPTCodeMissedOppHeatMapArr = obj;
      } else {
        state.insightsDenialbyCPTCodeMissedOppHeatMapArr = {};
        state.rangesArray.CPTCodeHeatMapArrMissedOpp = "";
      }
    },

    failedGroupByMissedOpp: (state, action) => {
      state.groupByMissedOppLoader = false;
    },
    //Group by GroupCode
    gettingGroupByCode: (state, action) => {
      state.groupByCodeLoader = true;
    },
    successGroupByCode: (state, action) => {
      const data = action.payload;
      state.groupByCodeLoader = false;
      if (data?.denialByReasonCodeHeatmap?.length > 0) {
        const finalDataReasonCode = data?.denialByReasonCodeHeatmap;

        const { finalArr } =
          getGroupByCodeDataReasonCodeDepartment(finalDataReasonCode);

        // let objGroupByDept = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: finalArr,
        //       name: "All",
        //     },
        //   ],
        // };

        state.groupByCodeCARC = finalArr;
      } else {
        state.groupByCodeCARC = [];
      }
    },

    failedGroupByCode: (state, action) => {
      state.groupByCodeLoader = false;
    },

    setCARCDash_Data: (state, action) => {
      let data = action.payload;
      // for  oncologyCARCTable Arr
      if (data?.CARCTable?.length > 0) {
        let denialAmount = 0;
        let chargeAmount = 0;
        let denialCount = 0;
        state.carcDetail.tabularDataArr = data?.CARCTable;
        data?.CARCTable?.map((item) => {
          denialAmount = denialAmount + item?.denielAmount;
          chargeAmount = chargeAmount + item?.chargeAmount;
          denialCount = denialCount + item?.denielCount;
        });

        state.totalCARCTableData = {
          denialAmount: denialAmount,
          chargeAmount: chargeAmount,
          denialCount: denialCount,
        };
        state.carcDetail.DenialPer = data?.DenialPer;
        state.carcDetail.DenialCount = data?.DenialCount;
        state.carcDetail.DenialAmount = data?.DenialAmount;
        state.carcDetail.ChargeAmount = data?.ChargeAmount;
      } else {
        state.carcDetail.tabularDataArr = [];
        state.carcDetail.DenialPer = null;
        state.carcDetail.DenialCount = null;
        state.carcDetail.DenialAmount = null;
        state.carcDetail.ChargeAmount = null;
        state.totalCARCTableData = {
          denialAmount: 0,
          chargeAmount: 0,
          denialCount: 0,
        };
      }

      // for  oncologyCARCCard Arr
      if (data?.oncologyCARCCard?.length > 0) {
        state.carcReasonCodeCards = data?.oncologyCARCCard;
      } else {
        state.carcReasonCodeCards = [];
      }
    },

    setCARCDashKPIS_Data: (state, action) => {
      let data = action.payload;

      // group-reason code handle here

      if (data?.reasonCodes?.length) {
        let newArr = [];
        data?.reasonCodes.forEach((item) => {
          newArr.push({
            label: `${item?.groupAndReasonCode} - ${item?.description}`,
            value: item?.groupAndReasonCode,
          });
        });

        state.carcGroupReasonCodeFilterArr = newArr;
      } else {
        state.carcGroupReasonCodeFilterArr = [];
      }

      // for handle department filter
      if (data?.departments?.length > 0) {
        state.cardDepartmentArrReasonCode = JSON.parse(data?.departments);
        // var newArr = [];
        // data?.departments.forEach((item, index) => {
        //   newArr.push(item?.name);
        // });
        // newArr.unshift("Departments(All)");
        // state.cardDepartmentArrReasonCode = newArr;
      } else {
        state.cardDepartmentArrReasonCode = [];
      }
      let newAmountArr = [];

      if (data?.oncologyCARCTrend && data?.oncologyCARCTrend?.length) {
        // handle reasonCode filter here
        // console.log("11111---->>");
        // hanlde multi-LineChart here

        if (data?.oncologyCARCTrend[0]?.reasonCode) {
          // hanlde multi-LineChart here

          if (data?.oncologyCARCTrend[0]?.filterType === "W") {
            // for new logic
            // console.log("222222---->>");

            let returnType = getMultiLineWeekChartData(
              data?.oncologyCARCTrend,
              "dateRange"
            );

            if (returnType) {
              if (returnType.length) {
                state.carcTrendsGraphArr = returnType;
              } else {
                state.carcTrendsGraphArr = [];
              }
            }
          } else {
            // old
            // console.log("333333---->>");

            let newAmountArr = [];

            let returnType = getMultiLineChartData(data?.oncologyCARCTrend);
            if (returnType) {
              // let denailAmount = [];
              for (let i = 0; i < returnType.uniqueReasonCode.length; i++) {
                newAmountArr.push({
                  // id: `Reason Code - ${returnType.uniqueReasonCode[i]}`,
                  id: `${returnType.uniqueReasonCode[i]}`,
                  color: "hsl(116, 70%, 50%)",
                  data: getDataForLineChart(
                    returnType.allDate[`${returnType.uniqueReasonCode[i]}`],
                    "monthName"
                  ),
                });
              }

              if (newAmountArr.length) {
                state.carcTrendsGraphArr = newAmountArr;
              } else {
                state.carcTrendsGraphArr = [];
              }
            }
          }
        } else {
          // handle without reasonCode filter
          // console.log("444444---->>");

          let denailAmount = [];
          let totalChargeAmount = [];

          data?.oncologyCARCTrend.forEach((item, index) => {
            denailAmount.push({
              x: `${
                ["m", "w"].includes(item?.filterType?.toLowerCase())
                  ? `Week ${index + 1}`
                  : // : item?.dateRange
                    // item?.month
                    item?.month
              }`,
              y:
                item?.denialAmount < 0
                  ? item?.denialAmount * -1
                  : item?.denialAmount,
              denialCount: item?.denialCount,
              denialAmount: item?.denialAmount,
              billedAmount: item?.totalAmount,
            });

            // totalChargeAmount.push({
            //   x: `${
            //     ["m", "w"].includes(item?.filterType?.toLowerCase())
            //       ? `Week ${index + 1}`
            //       : item?.dateRange
            //   }`,
            //   y: item?.totalAmount,
            //   denialCount: item?.denialCount,
            //   billedAmount: item?.totalAmount,
            // });
          });

          newAmountArr = [
            {
              id: "Denial Amount",
              color: "hsl(116, 70%, 50%)",
              data: denailAmount,
            },
          ];

          state.carcTrendsGraphArr = newAmountArr;
        }
      } else {
        state.carcTrendsGraphArr = [];
      }
    },

    // reasonCode Dash KPI's data
    setReasonCodDashKPIS_Data: (state, action) => {
      let data = action.payload;
      // console.log("setReasonCodDashKPIS_Data--->>", data);
      try {
        // payers filter
        if (data?.payers?.length) {
          // removing duplicate payerName here start
          const uniquePayerNames = {};
          const uniqueArrayOfObjects = data?.payers.filter((obj) => {
            if (!uniquePayerNames[obj.payerName]) {
              uniquePayerNames[obj.payerName] = true;
              return true;
            }
            return false;
          });
          // removing duplicate payerName here end

          // Filter out objects where primaryId is empty
          const filteredArrayOfObjects = uniqueArrayOfObjects.filter(
            (obj) => obj.primaryId
          );

          // Sort the array alphabetically based on payerName
          filteredArrayOfObjects.sort((a, b) => {
            const payerNameA = a.payerName.toLowerCase();
            const payerNameB = b.payerName.toLowerCase();
            if (payerNameA < payerNameB) return -1;
            if (payerNameA > payerNameB) return 1;
            return 0;
          });

          let newArr = [];
          filteredArrayOfObjects?.forEach((item) => {
            let fullName = `${item?.payerName} - ${item?.primaryId}`;
            newArr.push({
              label: item?.payerName,
              value: item?.payerId,
            });
          });

          state.reasonCodeDashPayerFilterArr = newArr;
        } else {
          state.reasonCodeDashPayerFilterArr = [];
        }

        // group reasonCodes filter
        if (data?.reasonCodes?.length) {
          // removing duplicate payerName here start
          const uniquePayerNames = {};
          const uniqueArrayOfObjects = data?.reasonCodes.filter((obj) => {
            if (!uniquePayerNames[obj.groupAndReasonCode]) {
              uniquePayerNames[obj.groupAndReasonCode] = true;
              return true;
            }
            return false;
          });
          // removing duplicate groupAndReasonCode here end

          // Sort the array alphabetically based on groupAndReasonCode
          uniqueArrayOfObjects.sort((a, b) => {
            const payerNameA = a.groupAndReasonCode.toLowerCase();
            const payerNameB = b.groupAndReasonCode.toLowerCase();
            if (payerNameA < payerNameB) return -1;
            if (payerNameA > payerNameB) return 1;
            return 0;
          });

          let newArr = [];
          uniqueArrayOfObjects?.forEach((item) => {
            let fullName = `${item?.groupAndReasonCode} - ${item?.description}`;
            newArr.push({
              label: fullName,
              value: item?.groupAndReasonCode,
              // description: item?.description,
            });
          });

          state.reasonCodeDashGroupReasonCodeFilterArr = newArr;
        } else {
          state.reasonCodeDashGroupReasonCodeFilterArr = [];
        }

        // for  reasonCode filter Arr
        if (data?.reasonCodes?.length > 0) {
          let newArr = [];

          data?.reasonCodes?.forEach((item) => {
            let reasonCode_and_desc = `${item?.reasonCode}${
              item?.description ? ` - ${item?.description}` : ""
            }`;

            newArr.push({
              label: reasonCode_and_desc,
              value: item?.reasonCode,
            });
          });

          // let trimmedArray = trimReasonCodes(newArr);

          state.reasonCodeDashFilterArr = newArr;
        } else {
          state.reasonCodeDashFilterArr = [];
        }

        // serviceLine filters handle here
        if (data?.departments?.length > 0) {
          state.departmentArrReasonCode = JSON.parse(data?.departments);
          // var newArr = [];
          // data?.departments.forEach((item, index) => {
          //   newArr.push(item?.name);
          // });
          // newArr.unshift("Departments(All)");
          // state.departmentArrReasonCode = newArr;
        } else {
          state.departmentArrReasonCode = [];
        }

        // lineChart logic handle here
        let returnType = carcHandleYearOverYear(data);
        let newAmountArr = [];
        // console.log("returnType--->>", returnType);

        if (returnType) {
          // console.log("1111--->>");

          // let denailAmount = [];
          for (let i = 0; i < returnType.uniqueYear.length; i++) {
            newAmountArr.push({
              id: `Year - ${returnType.uniqueYear[i]}`,
              color: "hsl(116, 70%, 50%)",
              data: getDataForLineChart(
                returnType.allDate[`${returnType.uniqueYear[i]}`],
                "monthName"
              ),
            });
          }
          if (newAmountArr.length) {
            state.reasonCodeDashGraphArr = newAmountArr;
          } else {
            state.reasonCodeDashGraphArr = [];
          }
        } else {
          if (data?.oncologyCARCTrend && data?.oncologyCARCTrend?.length) {
            if (data?.oncologyCARCTrend[0]?.reasonCode) {
              // hanlde multi-LineChart here
              // console.log("22222--->>");

              if (data?.oncologyCARCTrend[0]?.filterType === "W") {
                // for new logic

                let returnType = getMultiLineWeekChartData(
                  data?.oncologyCARCTrend,
                  "dateRange"
                );
                if (returnType) {
                  if (returnType.length) {
                    state.reasonCodeDashGraphArr = returnType;
                  } else {
                    state.reasonCodeDashGraphArr = [];
                  }
                }
              } else {
                // old
                // console.log("33333--->>");

                let newAmountArr = [];

                let returnType = getMultiLineChartData(data?.oncologyCARCTrend);
                if (returnType) {
                  // let denailAmount = [];
                  for (let i = 0; i < returnType.uniqueReasonCode.length; i++) {
                    newAmountArr.push({
                      // id: `Reason Code - ${returnType.uniqueReasonCode[i]}`,
                      id: `${returnType.uniqueReasonCode[i]}`,
                      color: "hsl(116, 70%, 50%)",
                      data: getDataForLineChart(
                        returnType.allDate[`${returnType.uniqueReasonCode[i]}`],
                        "monthName"
                      ),
                    });
                  }

                  if (newAmountArr.length) {
                    state.reasonCodeDashGraphArr = newAmountArr;
                  } else {
                    state.reasonCodeDashGraphArr = [];
                  }
                }
              }
            } else {
              // handle single-LineChart here
              // console.log("444444--->>");----

              let denailAmount = [];
              let totalChargeAmount = [];

              // handle week logic here without filters

              // handle quarter and full year logic here without filters

              data?.oncologyCARCTrend.forEach((item, index) => {
                // console.log("item--->>", item);
                denailAmount.push({
                  x: `${
                    ["m", "w"].includes(item?.filterType?.toLowerCase())
                      ? `Week ${index + 1}`
                      : // `Week ${item?.dateRange}`
                        item?.month
                  }`,
                  y:
                    item?.denialAmount < 0
                      ? item?.denialAmount * -1
                      : item?.denialAmount,
                  denialCount: item?.denialCount,
                  denialAmount: item?.denialAmount,
                  billedAmount: item?.totalAmount,
                });

                // totalChargeAmount.push({
                //   x: `${
                //     ["m", "w"].includes(item?.filterType?.toLowerCase())
                //       ? `Week ${index + 1}`
                //       : item?.dateRange
                //   }`,
                //   y: item?.totalAmount,
                //   denialCount: item?.denialCount,
                //   billedAmount: item?.totalAmount,
                // });
              });

              newAmountArr = [
                {
                  id: "Denial Amount",
                  color: "hsl(116, 70%, 50%)",
                  data: denailAmount,
                },
              ];

              state.reasonCodeDashGraphArr = newAmountArr;
            }
          } else {
            state.reasonCodeDashGraphArr = [];
          }
        }
      } catch (err) {
        // console.log("setReasonCodDashKPIS_Data_err--->>", err);
      }
    },
    // reasonCode BARCHART
    setReasonCodDashBarChartHard: (state, action) => {
      try {
        let data = action.payload;
        // console.log("111--->>", data);

        const colors = [
          "hsl(42, 70%, 50%)",
          "hsl(236, 70%, 50%)",
          "hsl(28, 70%, 50%)",
          "hsl(56, 100%, 50%)",
          "hsl(35, 100%, 50%)",
          "hsl(241, 100%, 50%)",
          "hsl(256, 100%, 50%)",
          "hsl(257, 100%, 50%)",
          "hsl(258, 100%, 50%)",
          "hsl(259, 100%, 50%)",
          "hsl(260, 100%, 50%)",
        ];

        //Hard Bar Chart
        if (data?.length > 0) {
          let hardAdjustment = data;

          let obj = {};
          let keyValues = {};

          if (hardAdjustment?.length) {
            hardAdjustment
              .sort((a, b) => b.denailAmount - a.denailAmount)
              ?.map((item, index) => {
                let reasonCode = item?.reasonCode;
                if (reasonCode) {
                  if (obj[reasonCode]) {
                    let arr = obj[reasonCode];
                    keyValues[item?.groupCode] = "";
                    arr.push({
                      denialAmount: item?.denailAmount,
                      groupCode: item?.groupCode,
                      per: item?.percentage,
                      description: item?.reasonCodeDescription,
                    });
                    obj[reasonCode] = arr;
                  } else {
                    let arr = [];
                    keyValues[item?.groupCode] = "";
                    arr.push({
                      denialAmount: item?.denailAmount,
                      groupCode: item?.groupCode,
                      per: item?.percentage,
                      description: item?.reasonCodeDescription,
                    });
                    obj[reasonCode] = arr;
                  }
                }
              });
          }
          let arrMain = [];
          Object.keys(obj)?.map((item1) => {
            let objNew = {
              id: item1,
            };
            let total = 0;
            obj[item1]?.map((item2, index) => {
              objNew[item2?.groupCode] = item2?.denialAmount;
              // objNew["desc12"] = item2?.description;
              total = total + item2?.denialAmount;
              // objNew[`${item2?.groupCode}Color`] = colors[index];
            });
            objNew.total = total;
            arrMain.push(objNew);
          });
          let finalArr = [];
          arrMain = arrMain
            ?.sort((a, b) => a.total - b.total)
            ?.map((item) => {
              let tempArr = { ...item }; // Create a new object
              delete tempArr.total;
              finalArr.push(tempArr);
            });
          // console.log("finalArr--->>", finalArr);
          state.ReasonCodeBarChart.hardAdjustmentsBarChart = finalArr;
          // state.ReasonCodeBarChart.keyValues = keyValuesMain;
        } else {
          state.ReasonCodeBarChart.hardAdjustmentsBarChart = [];
        }
      } catch (e) {
        // console.log("setReasonCodDashBarChartHard_e--->>", e);
      }
    },
    setReasonCodDashBarChartSoft: (state, action) => {
      let data = action.payload;
      const colors = [
        "hsl(42, 70%, 50%)",
        "hsl(236, 70%, 50%)",
        "hsl(28, 70%, 50%)",
        "hsl(56, 100%, 50%)",
        "hsl(35, 100%, 50%)",
        "hsl(241, 100%, 50%)",
        "hsl(256, 100%, 50%)",
        "hsl(257, 100%, 50%)",
        "hsl(258, 100%, 50%)",
        "hsl(259, 100%, 50%)",
        "hsl(260, 100%, 50%)",
      ];
      //Soft Bar Chart
      if (data?.length > 0) {
        let softAdjustment = [...data];
        // let softAdjustment = data;

        let obj = {};
        let keyValues = {};

        softAdjustment
          ?.sort((a, b) => b?.denailAmount - a?.denailAmount)
          ?.map((item, index) => {
            let reasonCode = item?.reasonCode;
            if (reasonCode) {
              if (obj[reasonCode]) {
                let arr = obj[reasonCode];
                keyValues[item?.groupCode] = "";
                arr.push({
                  denialAmount: item?.denailAmount,
                  groupCode: item?.groupCode,
                  per: item?.percentage,
                  description: item?.reasonCodeDescription,
                });
                obj[reasonCode] = arr;
              } else {
                let arr = [];
                keyValues[item?.groupCode] = "";
                arr.push({
                  denialAmount: item?.denailAmount,
                  groupCode: item?.groupCode,
                  per: item?.percentage,
                  description: item?.reasonCodeDescription,
                });
                obj[reasonCode] = arr;
              }
            }
          });
        let arrMain = [];
        Object.keys(obj)?.map((item1, index) => {
          let objNew = {
            id: item1,
          };

          let total = 0;
          obj[item1]?.map((item2, index) => {
            objNew[item2?.groupCode] = item2?.denialAmount;
            total = total + item2?.denialAmount;
            // objNew[`desc12`] = item2?.description;

            // objNew[`${item2?.groupCode}Color`] = colors[index];
          });
          objNew.total = total;
          arrMain.push(objNew);
        });
        const keyValuesMain = Object.keys(keyValues);

        let finalArr = [];
        arrMain = arrMain
          ?.sort((a, b) => a.total - b.total)
          ?.map((item) => {
            let tempArr = item;
            delete tempArr.total;
            finalArr.push(tempArr);
          });
        state.ReasonCodeBarChart.softAdjustmentsBarChart = finalArr;
        state.ReasonCodeBarChart.keyValues = keyValuesMain;
      } else {
        state.ReasonCodeBarChart.softAdjustmentsBarChart = [];
        state.ReasonCodeBarChart.keyValues = [];
      }
    },
    setDenialByReasonCodeBarChart: (state, action) => {
      let data = action.payload;
      // denialByReasonCode handle here
      // console.log("222--->>", data);
      if (data && data?.singleBarChart && data?.singleBarChart?.length) {
        let amountArr = [];
        data?.singleBarChart?.forEach((item) => {
          amountArr.push({
            country: item?.reasonCodeCategory
              ? item?.reasonCodeCategory
              : "Blank",

            reasonCodeCategory: item?.reasonCodeCategory
              ? item?.reasonCodeCategory
              : "Blank",
            reasonCodeDescription: item?.reasonCodeDescription
              ? item?.reasonCodeDescription
              : "Not available",
            denialCount: item?.denielCount,
            denialAmountOriginal: item?.denailAmount,
            denialAmount:
              item?.denailAmount < 0
                ? item?.denailAmount * -1
                : item?.denailAmount,
          });
        });

        let asc_amountArr = getAscArray(amountArr, "denialAmount");

        state.reasonCodeCategoryBarChart = asc_amountArr;
      } else {
        state.reasonCodeCategoryBarChart = [];
      }
    },

    // reasonCode Dash filter data
    setReasonCodeFilters_Data: (state, action) => {
      let data = action.payload;
      // for  reasonCode filter Arr
      if (data?.carcReasonCodeFilter?.length > 0) {
        let newArr = [];
        data?.carcReasonCodeFilter?.forEach((item) => {
          let reasonCode_and_desc = `${item?.reasonCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            label: reasonCode_and_desc,
            value: item?.reasonCode,
          });
        });

        state.reasonCodeDashFilterArr = newArr;
      } else {
        state.reasonCodeDashFilterArr = [];
      }
    },
    successCptCodeFilter2: (state, action) => {
      const data = action?.payload;
      // console.log("successCptCodeFilter2_--->>", data);

      let finalArr = [];
      let data1 = data?.data;
      const cptCodes = data?.data?.cptCodes;
      const selectedCptCodes = data?.data?.selectedCodes;

      // console.log("successCptCodeFilter2_if--->>", data1);
      if (cptCodes?.length) {
        if (selectedCptCodes?.length > 0) {
          const excludedValues = selectedCptCodes.map((obj) => obj?.cptCode);

          let arrTemp = [];
          let newArr1 = [];
          cptCodes?.filter((obj) => {
            let userFriendlyName = obj?.description.length
              ? `${obj?.cptCode} - ${obj?.description}`
              : `${obj?.cptCode}`;
            if (excludedValues.includes(obj?.cptCode)) {
              arrTemp.push({
                value: obj?.cptCode,

                label: userFriendlyName,
              });
            } else {
              newArr1.push({
                value: obj?.cptCode,

                label: userFriendlyName,
              });
            }
          });

          newArr1.unshift(...arrTemp);

          finalArr = newArr1;
          state.cptCodeList = newArr1;
          // console.log("11--->>", finalArr);

          // handleMultiFilter(state, finalArr, data?.listingFilter);
        } else {
          let newArr = [];

          cptCodes?.forEach((item) => {
            let userFriendlyName = item?.description.length
              ? `${item?.cptCode} - ${item?.description}`
              : `${item?.cptCode}`;
            newArr.push({
              value: item?.cptCode,

              label: userFriendlyName,
            });
          });

          finalArr = newArr;
          state.cptCodeList = newArr;

          // console.log("22--->>", finalArr);

          // handleMultiFilter(state, finalArr, data?.listingFilter);
        }
      } else {
        state.cptCodeList = [];
      }
    },
    successCptCodeFilter: (state, action) => {
      const data = action?.payload;
      const cptCodes = data?.data?.cptCodes;
      const selectedCptCodes = data?.data?.selectedCodes;
      // console.log("successCptCodeFilter--->>", data);
      // console.log("successCptCodeFilter__if--->>", data);
      if (cptCodes?.length) {
        if (selectedCptCodes?.length > 0) {
          const excludedValues = selectedCptCodes.map((obj) => obj?.cptCode);
          const excludedArray = selectedCptCodes.map((obj) => {
            let userFriendlyName = obj?.description.length
              ? `${obj?.cptCode} - ${obj?.description}`
              : `${obj?.cptCode}`;

            return {
              value: obj?.cptCode,
              label: userFriendlyName,
            };
          });

          let arrTemp = [];
          let newArr1 = [];
          cptCodes?.filter((obj) => {
            if (excludedValues.includes(obj?.cptCode)) {
              arrTemp.push(obj);
            } else {
              let userFriendlyName = obj?.description.length
                ? `${obj?.cptCode} - ${obj?.description}`
                : `${obj?.cptCode}`;
              newArr1.push({
                value: obj?.cptCode,
                label: userFriendlyName,
              });
            }
          });

          newArr1.unshift(...excludedArray);
          state.cptCodeList = newArr1;
        } else {
          let newArr = [];

          cptCodes?.forEach((item) => {
            let userFriendlyName = item?.description.length
              ? `${item?.cptCode} - ${item?.description}`
              : `${item?.cptCode}`;
            newArr.push({
              value: item?.cptCode,

              label: userFriendlyName,
            });
          });

          state.cptCodeList = newArr;
        }
      } else {
        state.cptCodeList = [];
      }

      // const cptCodes = data?.cptCodes;
      // if (cptCodes?.length) {
      //   if (data?.selectedCodes?.length > 0) {
      //     const excludedValues = data?.selectedCodes.map((obj) => obj?.cptCode);
      //     const excludedArray = data?.selectedCodes.map((obj) => {
      //       let userFriendlyName = obj?.description.length
      //         ? `${obj?.cptCode} - ${obj?.description}`
      //         : `${obj?.cptCode}`;

      //       return {
      //         value: obj?.cptCode,
      //         label: userFriendlyName,
      //       };
      //     });

      //     let arrTemp = [];
      //     let newArr1 = [];
      //     cptCodes?.filter((obj) => {
      //       if (excludedValues.includes(obj?.cptCode)) {
      //         arrTemp.push(obj);
      //       } else {
      //         let userFriendlyName = obj?.description.length
      //           ? `${obj?.cptCode} - ${obj?.description}`
      //           : `${obj?.cptCode}`;
      //         newArr1.push({
      //           value: obj?.cptCode,
      //           label: userFriendlyName,
      //         });
      //       }
      //     });

      //     newArr1.unshift(...excludedArray);

      //     state.cptCodesForFilterArr = newArr1;
      //     state.cptCodesForRAFilterArr = newArr1;
      //     state.cptCodesForRDFilterArr = newArr1;
      //     state.cptCodesForDBCFilterArr = newArr1;
      //     state.cptCodesForCAdjCLFilterArr = newArr1;
      //     state.cptCodesForDBPFilterArr = newArr1;
      //   } else {
      //     let newArr = [];

      //     cptCodes?.forEach((item) => {
      //       let userFriendlyName = item?.description.length
      //         ? `${item?.cptCode} - ${item?.description}`
      //         : `${item?.cptCode}`;
      //       newArr.push({
      //         value: item?.cptCode,

      //         label: userFriendlyName,
      //       });
      //     });

      //     state.cptCodesForFilterArr = newArr;
      //     state.cptCodesForRAFilterArr = newArr;
      //     state.cptCodesForCLFilterArr = newArr;
      //     state.cptCodesForRDFilterArr = newArr;
      //     state.cptCodesForDBCFilterArr = newArr;
      //     state.cptCodesForCAdjCLFilterArr = newArr;
      //     state.cptCodesForDBPFilterArr = newArr;
      //   }
      // } else {
      //   state.cptCodesForFilterArr = [];
      //   state.cptCodesForRAFilterArr = [];
      //   state.cptCodesForCLFilterArr = [];
      //   state.cptCodesForRDFilterArr = [];
      //   state.cptCodesForDBCFilterArr = [];
      //   state.cptCodesForCAdjCLFilterArr = [];
      //   state.cptCodesForDBPFilterArr = [];
      // }

      // state.filterLoader.cptCode = false;
    },
    // InsightsReasonCodeHeatmap

    setInsightsReasonCodeMap_Data: (state, action) => {
      let data = action.payload;
      // handle denialByReasonCodeHeatmap here
      if (data?.denialByReasonCodeHeatmap?.length > 0) {
        const finalData = data?.denialByReasonCodeHeatmap;

        const highest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );

        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.ReasonCodeHeatMapArr = range;

        const {
          countValue,
          newArr,
          groupByDeptObj,
          finalArrByDept,
          finalArrByCategory,
        } = getGroupByHeatMapDataCARC(finalData, range);
        state.groupByDepartmentCARC = finalArrByDept;
        state.groupByCategoryCARC = finalArrByCategory;
        // let obj = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: newArr,
        //       name: "All",
        //       value: countValue,
        //     },
        //   ],
        // };

        // state.insightsReasonCodeHeatMapArr = obj;
      } else {
        state.groupByDepartmentCARC = [];
        state.groupByCategoryCARC = [];
        // state.insightsReasonCodeHeatMapArr = {};
      }
      if (data?.denialByReasonCodeHeatmap_WithoutRepeation?.length > 0) {
        let newArr = [];
        let countValue = 0;
        const finalData = data?.denialByReasonCodeHeatmap_WithoutRepeation;
        const highest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );

        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.ReasonCodeHeatMapArr = range;
        finalData?.map((item, index) => {
          newArr.push({
            name: item?.reasonCode,
            actualValue: item?.denielAmount,
            value:
              item?.denielAmount < 0
                ? item?.denielAmount * -1
                : item?.denielAmount,
            ranges: { range: range, denialAmount: item?.denielAmount },

            chargeAmount: item?.chargeAmount,
            denialPercentage: item?.denialPercentage,
            denielCount: item?.denielCount,
            description: item?.description,
            totalCount: item?.totalCount,
          });
          countValue = countValue + item?.denielAmount;
        });

        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };
        state.insightsReasonCodeHeatMapArr = obj;
      } else {
        state.insightsReasonCodeHeatMapArr = {};
      }
      // handle denialbyCPTCodeHeatmap here
      if (data?.denialbyCPTCodeHeatmap?.length > 0) {
        const finalData = data?.denialbyCPTCodeHeatmap;
        const highest = data?.denialbyCPTCodeHeatmap?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = data?.denialbyCPTCodeHeatmap?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );

        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.CPTCodeHeatMapArr = range;

        const { countValue, newArr, groupByDeptObj, finalArr } =
          getGroupByHeatMapDataCPT(finalData, range);

        // let objGroupByDept = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: finalArr,
        //       name: "All",
        //     },
        //   ],
        // };
        state.groupByDepartmentCPT = finalArr;

        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };

        state.insightsDenialbyCPTCodeHeatMapArr = obj;
      } else {
        state.insightsDenialbyCPTCodeHeatMapArr = {};
        state.rangesArray.CPTCodeHeatMapArr = [];
      }

      // handle Departments here
      if (data?.departments?.length > 0) {
        state.departmentArr = JSON.parse(data?.departments);
        // var newArr = [];
        // data?.departments.forEach((item, index) => {
        //   newArr.push(item?.name);
        // });
        // newArr.unshift("Departments(All)");
        // state.departmentArr = newArr;
      } else {
        state.departmentArr = [];
      }

      // handle Facilities here
      if (data?.facilities?.length > 0) {
        let newArr = [];

        data?.facilities.splice(
          data?.facilities.findIndex((a) => a?.name === ""),
          1
        );
        data?.facilities?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.name,
          });
        });

        state.availableFacilitiesList = newArr;
      } else {
        state.availableFacilitiesList = [];
      }

      // handle hospitals here
      if (data?.hospitals?.length > 0) {
        let newArr = [];
        data?.hospitals?.forEach((item) => {
          if (item?.name) {
            newArr.push({
              label: item?.name,
              value: item?.name,
              fullName: item?.hospital,
            });
          }
        });
        state.availableHospitalsList = newArr;
      } else {
        state.availableHospitalsList = [];
      }
    },

    // filter for smart filter
    setInsightsSmartFilters: (state, action) => {
      let data = action.payload;
      const { ClaimStatusCodeIds } = data?.savedCode;

      if (data?.serviceDenialReasonCode?.length) {
        // for  serviceDenialReasonCode filter Arr
        let newArr = [];
        data?.serviceDenialReasonCode?.forEach((item) => {
          let desc = item?.code + ` - ${item?.description}`;
          newArr.push({
            label: desc,
            value: item?.code,
          });
        });

        state.insightsSmartFilterArr = newArr;
      } else {
        state.insightsSmartFilterArr = [];
      }

      if (ClaimStatusCodeIds) {
        let newArr = ClaimStatusCodeIds?.split(",")?.map((item) =>
          parseInt(item)
        );
        state.claimStatusCodeIdsSelected = newArr;
      } else {
        state.claimStatusCodeIdsSelected = [];
      }

      if (data?.claimStatusCode?.length) {
        let newArr = [];
        data?.claimStatusCode?.forEach((item) => {
          let desc = item?.claimStatusCodeId + ` - ${item?.description}`;
          newArr.push({
            label: desc,
            value: item?.claimStatusCodeId,
          });
        });

        state.claimStatus23FilterArr = newArr;
      } else {
        state.claimStatus23FilterArr = [];
      }
    },
    setCarcGraphData: (state, action) => {
      let data = action.payload;

      const allData = data?.getAllCARCDistributionData;
      const top20Data = data?.getTop20CARCDistributionData;

      let scatterData = [];
      let scatterReChartData = [];
      let bubbleData = [];

      if (allData?.length > 0 || top20Data?.length > 0) {
        scatterData = allData?.map((item) => {
          return {
            id: item.groupAndReasonCode,
            data: [
              {
                x: item?.serviceAdjustmentCount,
                y: item?.sumAmount,
              },
            ],
          };
        });
        scatterReChartData = allData?.map((item) => {
          return {
            id: item.groupAndReasonCode,
            x: item?.serviceAdjustmentCount,
            y: item?.sumAmount,
          };
        });
        bubbleData = top20Data?.map((item) => {
          return {
            code: item?.groupAndReasonCode,
            count: item?.serviceAdjustmentCount,
            amount: item?.sumAmount,
            size: item?.sumAmount.slice(0, 2),
          };
        });
      }

      state.scatterGraphAllData = scatterData || [];
      state.scatterGraphAllReChartData = scatterReChartData || [];
      state.bubbleChartData = bubbleData || [];
    },

    setDepCarcTableData: (state, action) => {
      let data = action.payload;
      // console.log(">> data ", data);
      if (data?.length > 0) {
        state.depCarcTableData = data;
      } else {
        state.depCarcTableData = [];
      }
      state.depCarcTableLoader = false;
    },
    setDepCarcTreeMapData: (state, action) => {
      let data = action.payload;
      if (data?.length > 0) {
        // state.depCarcTableData = data;
        let finalData = {
          name: "Top 10",
          color: "hsl(11, 70%, 50%)",
          children: [],
        };

        let groupedByName = data.reduce((acc, obj) => {
          if (!acc[obj.level0Name]) {
            acc[obj.level0Name] = [];
          }
          acc[obj.level0Name].push(obj);
          return acc;
        }, {});

        let names = Object.values(groupedByName);
        let newArr = [];
        let newCheck = [];

        for (let index = 0; index < names?.length; index++) {
          newArr.push(
            names[index].map((item) => {
              return {
                name: item?.carcCode,
                loc: item?.totalDenialAmount,
                color: "hsl(35, 70%, 50%)",
                title: item?.level0Name,
                desc: item?.carcDescription,
                denialAmount: item?.totalDenialAmount,
              };
            })
          );
        }
        for (let index = 0; index < newArr?.length; index++) {
          newCheck.push({
            name: newArr[index]?.[0]?.title,
            color: "hsl(74, 70%, 50%)",
            children: newArr[index],
          });
        }
        // console.log(">> first check ", newCheck);

        // for (let index = 0; index < newArr.length; index++) {
        //   newCheck.push({
        //     name: newArr[index]?.[0]?.title,
        //     brand: newArr[index]?.[0]?.title,
        //     value: 1,
        //     children: newArr[index],
        //   });
        // }
        finalData.children = newCheck.slice(0, 6);

        state.depCarcTreeMapData = finalData;
      } else {
        state.depCarcTreeMapData = [];
      }
      state.depCarcTreeMapLoader = false;
    },

    setDepCarcLoaders: (state, action) => {
      let data = action.payload;
      // console.log(">> data", data);
      state.depCarcTableLoader = data;
      state.depCarcTreeMapLoader = data;
    },
    setDepCptTableData: (state, action) => {
      let data = action.payload;
      // console.log(">> data ", data);
      if (data?.length > 0) {
        state.depCptTableData = data;
      } else {
        state.depCptTableData = [];
      }
      state.depCptTableLoader = false;
    },

    setDepCptTreeMapData: (state, action) => {
      let data = action.payload;
      // console.log(">> data1 ", data);
      if (data?.length > 0) {
        // state.depCarcTableData = data;
        let finalData = {
          name: "Top 10",
          color: "hsl(11, 70%, 50%)",
          children: [],
        };

        let groupedByName = data.reduce((acc, obj) => {
          if (!acc[obj.level0Name]) {
            acc[obj.level0Name] = [];
          }
          acc[obj.level0Name].push(obj);
          return acc;
        }, {});

        let names = Object.values(groupedByName);
        let newArr = [];
        let newCheck = [];

        for (let index = 0; index < names?.length; index++) {
          newArr.push(
            names[index].map((item) => {
              return {
                name: item?.cptCode,
                loc: item?.totalDenialAmount,
                color: "hsl(35, 70%, 50%)",
                title: item?.level0Name,
                desc: item?.cptCodeDescription,
                denialAmount: item?.totalDenialAmount,
              };
            })
          );
        }
        for (let index = 0; index < newArr?.length; index++) {
          newCheck.push({
            name: newArr[index]?.[0]?.title,
            color: "hsl(74, 70%, 50%)",
            children: newArr[index],
          });
        }
        // console.log(">> first check ", newCheck);

        // for (let index = 0; index < newArr.length; index++) {
        //   newCheck.push({
        //     name: newArr[index]?.[0]?.title,
        //     brand: newArr[index]?.[0]?.title,
        //     value: 1,
        //     children: newArr[index],
        //   });
        // }

        finalData.children = newCheck.slice(0, 6);
        // console.log(">> final check ", finalData);

        state.depCptTreeMapData = finalData;
      } else {
        state.depCptTreeMapData = [];
      }
      state.depCptTreeMapLoader = false;
    },

    setDepCptLoaders: (state, action) => {
      let data = action.payload;

      state.depCptTableLoader = data;
      state.depCptTreeMapLoader = data;

      // depCarcTableData:[],
      // depCarcTreeMapData:[],
    },
    setDepRarcTableData: (state, action) => {
      let data = action.payload;
      // console.log(">> 123123 data ", data);
      if (data?.length > 0) {
        state.depRarcTableData = data;
      } else {
        state.depRarcTableData = [];
      }
      state.depRarcTableLoader = false;
    },

    setDepRarcTreeMapData: (state, action) => {
      let data = action.payload;
      // console.log(">> data ", data);
      if (data?.length > 0) {
        // state.depCarcTableData = data;
        let finalData = {
          name: "Top 10",
          color: "hsl(11, 70%, 50%)",
          children: [],
        };

        let groupedByName = data.reduce((acc, obj) => {
          if (!acc[obj.level0Name]) {
            acc[obj.level0Name] = [];
          }
          acc[obj.level0Name].push(obj);
          return acc;
        }, {});

        let names = Object.values(groupedByName);
        let newArr = [];
        let newCheck = [];

        for (let index = 0; index < names?.length; index++) {
          newArr.push(
            names[index].map((item) => {
              return {
                name: item?.rarcCode,
                loc: item?.totalDenialAmount,
                color: "hsl(35, 70%, 50%)",
                title: item?.level0Name,
                desc: item?.rarcDescription,
                denialAmount: item?.totalDenialAmount,
              };
            })
          );
        }
        for (let index = 0; index < newArr?.length; index++) {
          newCheck.push({
            name: newArr[index]?.[0]?.title,
            color: "hsl(74, 70%, 50%)",
            children: newArr[index],
          });
        }
        // console.log(">> first check ", newCheck);

        // for (let index = 0; index < newArr.length; index++) {
        //   newCheck.push({
        //     name: newArr[index]?.[0]?.title,
        //     brand: newArr[index]?.[0]?.title,
        //     value: 1,
        //     children: newArr[index],
        //   });
        // }
        finalData.children = newCheck.slice(0, 6);

        state.depRarcTreeMapData = finalData;
      } else {
        state.depRarcTreeMapData = [];
      }
      state.depRarcTreeMapLoader = false;
    },

    setDepRarcLoaders: (state, action) => {
      let data = action.payload;

      state.depRarcTableLoader = data;
      state.depRarcTreeMapLoader = data;

      // depCarcTableData:[],
      // depCarcTreeMapData:[],
    },
  },
});

export const {
  setCARCFilters_Data,
  setInsightsSmartFilters,
  setReasonCodeFilters_Data,
  setCARCDash_Data,
  setCARCDashKPIS_Data,
  setInsightsReasonCodeMap_Data,
  setReasonCodDashKPIS_Data,
  setReasonCodDashBarChartHard,
  setReasonCodDashBarChartSoft,
  successFilters,
  gettingGroupByAll,
  successGroupByAll,
  failedGroupByAll,
  gettingGroupByRemarkCode,
  successGroupByRemarkCode,
  failedGroupByRemarkCode,
  gettingGroupByCode,
  successGroupByCode,
  failedGroupByCode,
  gettingGroupByCategory,
  successGroupByCategory,
  failedGroupByCategory,
  gettingGroupByDepartment,
  successGroupByDepartment,
  failedGroupByDepartment,
  gettingGroupByMissedOpp,
  successGroupByMissedOpp,
  failedGroupByMissedOpp,
  setCarcGraphData,
  setDenialByReasonCodeBarChart,
  successCptCodeFilter,
  successCptCodeFilter2,

  setDepCarcTableData,
  setDepCarcLoaders,

  setDepCarcTreeMapData,

  setDepCptTableData,
  setDepCptLoaders,
  setDepCptTreeMapData,

  setDepRarcTableData,
  setDepRarcLoaders,
  setDepRarcTreeMapData,
} = OncologyCARCSlice.actions;

export const getInitialData = (targetFilter) => async (dispatch, getState) => {
  const res = await genericAxiosCall(
    serverRoutes?.GET_SERVICE_LINES_LIST,
    "get"
  );
  const data = JSON.parse(res.data.data.departmentJson).filter(
    (item) => item.Level0Name == "Oncology"
  );

  let arr = [];
  arr.push({
    Name: data[0].Level0Name,
    Level0Name: data[0].Level0Name,
    Level0Id: data[0].Level0Id,
    Id: data[0].Level0Id,
    Level: data[0].Level,
    fullPath: [
      {
        Name: data[0].Level0Name,
        Level0Name: data[0].Level0Name,
        Level0Id: data[0].Level0Id,
        Id: data[0].Level0Id,
        Level: data[0].Level,
      },
    ],
  });

  dispatch(
    setListingState({
      listing: targetFilter,
      value: {
        treeNodeServiceLine: arr,
        pageNumber: 1,
      },
    })
  );
  return arr;
};

// get CARC Trends KPIs
export const gettingOncologyCARCKPIS_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "carcTrendsDashboardFilter",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );
      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      let data = advStngServiceLineExecList?.split(",")?.map((item) => {
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      dispatch(
        setListingState({
          listing: "carcTrendsDashboardFilter",
          value: {
            smartFilterArr: data,
            pageNumber: 1,
          },
        })
      );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }
    let sliceState = getState().npReducers.filters.carcTrendsDashboardFilter;
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    try {
      // ReasonCode

      let exludedReasonCode = [];

      let res = getExludedReasonCode(
        sliceState?.smartFilter,
        sliceState?.smartFilterArr,
        sliceState?.ReasonCode,
        dispatch,
        "ReasonCode",
        "carcTrendsDashboardFilter"
      );
      exludedReasonCode = res?.reasonCodeFinalArr;

      dispatch(
        gettingSmartFilterForOncologyDenial_Call("carcTrendsDashboardFilter")
      );

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState.oncologyType === true && {
          Oncology: sliceState.oncologyType,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter:
            sliceStateForDate.dateMainState == "Months"
              ? "W"
              : sliceStateForDate.dateMainState == "Years"
              ? "Y"
              : "Q",
        }),

        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        Oncology: true,
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState.oncologyType === true && {
          Oncology: sliceState.oncologyType,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter:
            sliceStateForDate.dateMainState == "Months"
              ? "W"
              : sliceStateForDate.dateMainState == "Years"
              ? "Y"
              : "Q",
        }),

        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        Oncology: true,
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObjInsights(
          sliceState.treeNodeServiceLine
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      constDate = sliceState?.selFilterType;

      // console.log("api_call--->>", params);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCARCDashKPIS_Data,
        "carcTrendsDashboardFilter",
        "GET_OCOLOGY_CARC_TRENDS_DASHBOARD_KPIS_GRAPH",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setCARCFilters_Data
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

//

// carc overview barchart
export const gettingReasonCodeDashKPIS_CARC_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "reasonCodeDashboardFilter",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );
      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      let data = advStngServiceLineExecList?.split(",")?.map((item) => {
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      dispatch(
        setListingState({
          listing: "reasonCodeDashboardFilter",
          value: {
            smartFilterArr: data,
            pageNumber: 1,
          },
        })
      );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }

    const { outputList } = getState().npReducers.denialsCategoriesConfig;

    const check = outputList.map((item, i) => {
      return { [`smartFilter${i + 1}`]: item.reasonCodes };
    });

    let combinedObject = {};

    check.forEach((obj) => {
      const key = Object.keys(obj)[0]; // Get the key of the current object
      let value = obj[key]; // Get the value associated with the key

      // Remove spaces and ensure only commas separate the values
      value = value.replace(/\s/g, ""); // Remove all spaces
      value = value.replace(/,,/g, ","); // Remove any double commas (if any)

      combinedObject[key] = value;
    });

    // console.log("123123 output ", outputList, combinedObject);

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceStateUser = getState().pReducers.user;

    let sliceState = getState().npReducers.filters.reasonCodeDashboardFilter;

    try {
      // console.log("gettingReasonCodeDashKPIS_Call--->>");
      let exludedReasonCode = [];

      let res = getExludedReasonCode(
        sliceState?.smartFilter,
        sliceState?.smartFilterArr,
        sliceState?.reasonCodeFilter,
        dispatch,
        "reasonCodeFilter",
        "reasonCodeDashboardFilter"
      );
      exludedReasonCode = res?.reasonCodeFinalArr;

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),
        ...(sliceState?.groupReasonCodeFilter?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCodeFilter
          ),
        }),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };
      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),

        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),
        ...(sliceState?.groupReasonCodeFilter?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCodeFilter
          ),
        }),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };
      let paramsTempCarc = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),

        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };
      let paramsCarc = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),

        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),

        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      let paramsCarcCatBarChart = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: "Y",
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
        ...combinedObject,
      };

      //=====================
      let sliceState12 =
        getState().npReducers.filters["reasonCodeDashboardFilter"];
      let cacheData = { ...sliceState12.cachePagination };
      let cachParamsState = { ...sliceState12.cacheParams };
      let cache = cacheData[`page_${sliceState12.pageNumber}`];

      if (
        cache &&
        // cache.claims.length > 0 &&
        JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
      ) {
        // dispatch(stateValueSetter(cache));
        // return;
      } else {
        dispatch(
          gettingSmartFilterForOncologyDenial_Call("reasonCodeDashboardFilter")
        );
      }
      //=====================

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.treeNodeServiceLine
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }
      constDate = sliceStateForDate?.selFilterType;

      // console.log("params_gettingReasonCodeDashKPIS_Call--->>", params);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      //BARCHART
      let barChartParams = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),
        SmartFilter: getMultiSelectFilterValueForApi(
          sliceState?.smartFilterArr
        ),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),
      };
      let barChartParamsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),
        SmartFilter: getMultiSelectFilterValueForApi(
          sliceState?.smartFilterArr
        ),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };
      //Scatter Chart
      commonPagination(
        getState,
        paramsTempCarc,
        paramsCarc,
        dispatch,
        setCarcGraphData,
        "reasonCodeDashboardFilter",
        "GET_CARC_DASHBOARD_GRAPH_DATA",
        false
      );
      //Reason Code Bar Chart
      // commonPagination(
      //   getState,
      //   barChartParamsTemp,
      //   barChartParams,
      //   dispatch,
      //   setDenialByReasonCodeBarChart,
      //   "reasonCodeDashboardDenialByReasonCode",
      //   "GET_CLAIM_DASH_DENIAL_BY_PAYER_AND_REASON_CODE_KPIS",
      //   false
      //   // "GET_OCOLOGY_CARC_FILTER_KPIS",
      //   // setReasonCodeFilters_Data
      // );
      //HARD
      commonPagination(
        getState,
        barChartParamsTemp,
        barChartParams,
        dispatch,
        setReasonCodDashBarChartHard,
        "reasonCodeDashboardBarChartFilterHard",
        "GET_OCOLOGY_CARC_BARCHART_HARD",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );
      //SOFT
      commonPagination(
        getState,
        barChartParamsTemp,
        barChartParams,
        dispatch,
        setReasonCodDashBarChartSoft,
        "reasonCodeDashboardBarChartFilterSoft",
        "GET_OCOLOGY_CARC_BARCHART_SOFT",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );
      //BARCHART END

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setReasonCodDashKPIS_Data,
        "reasonCodeDashboardFilter",
        "GET_OCOLOGY_CARC_DASHBOARD_KPIS_GRAPH",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );

      // Reason Code Bar Chart
      commonPagination(
        getState,
        paramsCarcCatBarChart,
        paramsCarcCatBarChart,
        dispatch,
        setDenialByReasonCodeBarChart,
        "reasonCodeDashboardDenialByReasonCode",
        "GET_ONCOLOGY_DASH_SOFTDENIAL_BARCHART",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };
//

// get reaconCode dashboard KPIs
export const gettingReasonCodeDashKPIS_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      if (clear) {
        dispatch(
          resetListingState({
            listing: "reasonCodeDashboardFilter",
            ignore: ["claimStatus,payers,frequencyType,search"],
          })
        );
        // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
        let advStngServiceLineExecList =
          getState().pReducers.user.advStngServiceLineExecList;
        if (advStngServiceLineExecList?.length) {
          // console.log("api_call_if--->>");
          let data = advStngServiceLineExecList?.split(",")?.map((item) => {
            return {
              label: item.toString(),
              value: item.toString(),
            };
          });
          dispatch(
            setListingState({
              listing: "reasonCodeDashboardFilter",
              value: {
                smartFilterArr: data,
                pageNumber: 1,
              },
            })
          );
        } else {
          // console.log("api_call_else--->>");
        }

        // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
      }

      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      if (advStngServiceLineExecList?.length) {
        // console.log("api_call_if--->>");
        let data = advStngServiceLineExecList?.split(",")?.map((item) => {
          return {
            label: item.toString(),
            value: item.toString(),
          };
        });
        dispatch(
          setListingState({
            listing: "reasonCodeDashboardFilter",
            value: {
              smartFilterArr: data,
              pageNumber: 1,
            },
          })
        );
      } else {
        // console.log("api_call_else--->>");
        await dispatch(gettingAdvSettingData_Call());
      }

      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============

      const { outputList } = getState().npReducers.denialsCategoriesConfig;

      const check = outputList.map((item, i) => {
        return { [`smartFilter${i + 1}`]: item.reasonCodes };
      });

      let combinedObject = {};

      check.forEach((obj) => {
        const key = Object.keys(obj)[0]; // Get the key of the current object
        let value = obj[key]; // Get the value associated with the key

        // Remove spaces and ensure only commas separate the values
        value = value.replace(/\s/g, ""); // Remove all spaces
        value = value.replace(/,,/g, ","); // Remove any double commas (if any)

        combinedObject[key] = value;
      });

      let sliceStateForDate =
        getState().npReducers.filters.insightsSreensCommonFilter;
      let sliceStateUser = getState().pReducers.user;

      let sliceState = getState().npReducers.filters.reasonCodeDashboardFilter;
      let sliceStateForSmartFilters =
        getState().npReducers.filters.mainOrgDashboardFilter;

      let customSliceSmartFilters =
        getState().pReducers.user.advStngServiceLineExecList;

      let exludedReasonCode = [];

      let res = getExludedReasonCode(
        sliceState?.smartFilter,
        sliceState?.smartFilterArr,
        sliceState?.reasonCodeFilter,
        dispatch,
        "reasonCodeFilter",
        "reasonCodeDashboardFilter"
      );
      exludedReasonCode = res?.reasonCodeFinalArr;

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),
        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),
        ...(sliceState?.groupReasonCodeFilter?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCodeFilter
          ),
        }),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),

        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),
        ...(sliceState?.groupReasonCodeFilter?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCodeFilter
          ),
        }),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
      };
      let paramsTempCarc = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),
        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),

        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
      };
      let paramsCarc = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),

        ...(exludedReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(exludedReasonCode),
        }),

        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
      };

      let paramsCarcCatBarChart = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: "Y",
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
        ...combinedObject,
      };

      //=====================
      let sliceState12 =
        getState().npReducers.filters["reasonCodeDashboardFilter"];
      let cacheData = { ...sliceState12.cachePagination };
      let cachParamsState = { ...sliceState12.cacheParams };
      let cache = cacheData[`page_${sliceState12.pageNumber}`];

      if (
        cache &&
        // cache.claims.length > 0 &&
        JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
      ) {
        // dispatch(stateValueSetter(cache));
        // return;
      } else {
        dispatch(
          gettingSmartFilterForOncologyDenial_Call("reasonCodeDashboardFilter")
        );
      }
      //=====================

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.treeNodeServiceLine
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }
      constDate = sliceStateForDate?.selFilterType;

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      // BARCHART
      let barChartParams = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),
        // ...(sliceStateForSmartFilters?.smartFilter &&
        //   sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
        //     SmartFilter: getMultiSelectFilterValueForApi(
        //       sliceStateForSmartFilters?.smartFilterArr
        //     ),
        //   }),
        ...(sliceStateForSmartFilters?.smartFilter &&
          (sliceStateForSmartFilters?.smartFilterArr?.length > 0
            ? {
                SmartFilter: getMultiSelectFilterValueForApi(
                  sliceStateForSmartFilters?.smartFilterArr
                ),
              }
            : {
                SmartFilter: customSliceSmartFilters,
              })),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
      };
      let barChartParamsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),
        ...(sliceStateForSmartFilters?.smartFilter &&
          (sliceStateForSmartFilters?.smartFilterArr?.length > 0
            ? {
                SmartFilter: getMultiSelectFilterValueForApi(
                  sliceStateForSmartFilters?.smartFilterArr
                ),
              }
            : {
                SmartFilter: customSliceSmartFilters,
              })),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
      };

      //Scatter Chart
      commonPagination(
        getState,
        paramsTempCarc,
        paramsCarc,
        dispatch,
        setCarcGraphData,
        "reasonCodeDashboardFilter",
        "GET_CARC_DASHBOARD_GRAPH_DATA",
        false
      );
      //Reason Code Bar Chart
      commonPagination(
        getState,
        barChartParamsTemp,
        barChartParams,
        dispatch,
        setDenialByReasonCodeBarChart,
        "reasonCodeDashboardDenialByReasonCode",
        "GET_CLAIM_DASH_DENIAL_BY_PAYER_AND_REASON_CODE_KPIS",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );
      //HARD
      commonPagination(
        getState,
        barChartParamsTemp,
        barChartParams,
        dispatch,
        setReasonCodDashBarChartHard,
        "reasonCodeDashboardBarChartFilterHard",
        "GET_OCOLOGY_CARC_BARCHART_HARD",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );
      //SOFT
      commonPagination(
        getState,
        barChartParamsTemp,
        barChartParams,
        dispatch,
        setReasonCodDashBarChartSoft,
        "reasonCodeDashboardBarChartFilterSoft",
        "GET_OCOLOGY_CARC_BARCHART_SOFT",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );
      //BARCHART END

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setReasonCodDashKPIS_Data,
        "reasonCodeDashboardFilter",
        "GET_OCOLOGY_CARC_DASHBOARD_KPIS_GRAPH",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );
    } catch (e) {
      console.log("e--->>", e);
    }
  };

// ========== CARC individual Slicer ========

// get reaconCode dashboard KPIs
export const gettingCARCDashKPIS_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      console.log("gettingCARCDashKPIS_Call--->>");
      if (clear) {
        dispatch(
          resetListingState({
            listing: "reasonCodeDashboardFilter",
            ignore: ["claimStatus,payers,frequencyType,search"],
          })
        );
        // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
        let advStngServiceLineExecList =
          getState().pReducers.user.advStngServiceLineExecList;
        if (advStngServiceLineExecList?.length) {
          // console.log("api_call_if--->>");
          let data = advStngServiceLineExecList?.split(",")?.map((item) => {
            return {
              label: item.toString(),
              value: item.toString(),
            };
          });
          dispatch(
            setListingState({
              listing: "CarcDashboardFilter",
              value: {
                smartFilterArr: data,
                pageNumber: 1,
              },
            })
          );
        } else {
          // console.log("api_call_else--->>");
        }

        // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
      }

      let sliceStateForDate =
        getState().npReducers.filters.insightsSreensCommonFilter;
      let sliceStateUser = getState().pReducers.user;

      let sliceState = getState().npReducers.filters.CarcDashboardFilter;

      console.log("sliceStateUser--->>", sliceStateUser);
      console.log("sliceStateForDate--->>", sliceStateForDate);

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),

        ...(sliceState?.groupReasonCodeFilter?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCodeFilter
          ),
        }),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),

        ...(sliceState?.groupReasonCodeFilter?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCodeFilter
          ),
        }),
        ...(sliceState?.payersFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(sliceState?.payersFilter),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdFromDate =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId;

            // Check if clientIdFromDate is NaN or invalid and use sliceStateUser.clientId if so
            return isNaN(clientIdFromDate) || clientIdFromDate == null
              ? sliceStateUser?.clientId || getCurrentClientId()
              : clientIdFromDate;
          })(),
        }),
      };

      //=====================
      let sliceState12 =
        getState().npReducers.filters["reasonCodeDashboardFilter"];
      let cacheData = { ...sliceState12.cachePagination };
      let cachParamsState = { ...sliceState12.cacheParams };
      let cache = cacheData[`page_${sliceState12.pageNumber}`];

      if (
        cache &&
        // cache.claims.length > 0 &&
        JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
      ) {
        // dispatch(stateValueSetter(cache));
        // return;
      } else {
        dispatch(
          gettingSmartFilterForOncologyDenial_Call("CarcDashboardFilter")
        );
      }
      //=====================

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.treeNodeServiceLine
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }
      constDate = sliceStateForDate?.selFilterType;

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setReasonCodDashKPIS_Data,
        "CarcDashboardFilter",
        "GET_OCOLOGY_CARC_DASHBOARD_KPIS_GRAPH",
        false
        // "GET_OCOLOGY_CARC_FILTER_KPIS",
        // setReasonCodeFilters_Data
      );
    } catch (e) {
      console.log("e--->>", e);
    } finally {
      // reasonCodeDashboardFilter
      dispatch(
        setListingState({
          listing: "CarcDashboardFilter",
          value: {
            loadingMore: "",
            loadingFilters: "",
          },
        })
      );
    }
  };

//

export const gettingTabularOncologyCARC_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "carcTrendsDashboardFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.carcTrendsDashboardDetailFilter;
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForFilter =
      getState().npReducers.filters.carcTrendsDashboardFilter;
    let sliceStateForTime =
      getState().npReducers.filters.carcTrendsDashboardFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceStateForTime?.from !== "" && {
          from: sliceStateForTime.from,
        }),
        ...(sliceStateForTime?.to !== "" && {
          to: sliceStateForTime.to,
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),
        ...(sliceStateForFilter?.ReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(
            sliceStateForFilter?.ReasonCode
          ),
        }),
        ...(sliceStateForFilter?.groupReasonCode?.length > 0 && {
          reasonGroupCode: getMultiSelectFilterValueForApi(
            sliceStateForFilter?.groupReasonCode
          ),
        }),

        ...(sliceStateForFilter?.Category?.length > 0 && {
          category: getMultiSelectFilterValueForApi(
            sliceStateForFilter?.Category
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceStateForTime?.from !== "" && {
          from: sliceStateForTime.from,
        }),
        ...(sliceStateForTime?.to !== "" && {
          to: sliceStateForTime.to,
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),
        ...(sliceStateForFilter?.ReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(
            sliceStateForFilter?.ReasonCode
          ),
        }),
        ...(sliceStateForFilter?.groupReasonCode?.length > 0 && {
          reasonGroupCode: getMultiSelectFilterValueForApi(
            sliceStateForFilter?.groupReasonCode
          ),
        }),
        ...(sliceStateForFilter?.Category?.length > 0 && {
          category: getMultiSelectFilterValueForApi(
            sliceStateForFilter?.Category
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };

      if (sliceStateForFilter?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceStateForFilter.treeNodeServiceLine
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      // console.log("params--->>", params);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCARCDash_Data,
        "carcTrendsDashboardDetailFilter",
        "GET_OCOLOGY_CARC_DASHBOARD_TABULAR_DATA",
        true,
        "GET_OCOLOGY_CARC_FILTER_KPIS",
        setCARCFilters_Data
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// get one reasonCode details
export const getOneReasonCodeCARCTrendData_Call =
  (code) => async (dispatch, getState) => {
    let sliceStateForDate =
      getState().npReducers.filters.carcTrendsDashboardFilter;

    let sliceState =
      getState().npReducers.filters.denialByFacilityDashFilterForTable2Dropdown;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...{
          ReasonCode: code,
        },
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ONE_REASON_CODE_OCOLOGY_CARC_DASHBOARD_KPIS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };
//Reason Code Heatmap Filters
const gettingDenialsHeatmapFilters =
  (paramsTemp, params) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilterAll;
    params = { ...params, start: 0, limit: 100, CPTCode: "" };
    paramsTemp = { ...paramsTemp, start: 0, limit: 100, CPTCode: "" };
    try {
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      await commonPaginationForPost(
        getState,
        paramsTemp,
        params,
        dispatch,
        successFilters,
        "insightReasonCodeHeatMapFilter",
        "GET_INSIGHTS_REASON_CODE_HEAT_MAP_FILTERS",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
// Reason Code Heatmap Dashboard
const groupByAllCall = (paramsTemp, params) => async (dispatch, getState) => {
  let sliceState =
    getState().npReducers.filters.insightReasonCodeHeatMapFilterAll;

  let start = sliceState.pageNumber;
  let limit = sliceState.pageSize;

  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    // start = rec_val + 1;
    start = rec_val;
  }
  if (start == 1) {
    start = 0;
  }

  try {
    params = { ...params, groupBy: "actual" };
    paramsTemp = { ...paramsTemp, groupBy: "actual" };

    // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

    await commonPaginationForPost(
      getState,
      paramsTemp,
      params,
      dispatch,
      successGroupByAll,
      "insightReasonCodeHeatMapFilterAll",
      "GET_INSIGHTS_REASON_CODE_HEAT_MAP",
      null,
      "",
      ""
    );
  } catch (e) {
    // console.log("catch--->>", e);
  }
};
const groupByRemarkCodeCall =
  (paramsTemp, params) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilterAll;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      params = { ...params, groupBy: "remarkCode" };
      paramsTemp = { ...paramsTemp, groupBy: "remarkCode" };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      await commonPaginationForPost(
        getState,
        paramsTemp,
        params,
        dispatch,
        successGroupByRemarkCode,
        "insightReasonCodeHeatMapFilterRemarkCode",
        "GET_INSIGHTS_REASON_CODE_HEAT_MAP",
        null,
        "",
        ""
      );
    } catch (e) {
      console.log("catch--->>", e);
    }
  };
const groupByCategoryCall =
  (paramsTemp, params) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilterCategory;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      params = { ...params, groupBy: "Category" };
      paramsTemp = { ...paramsTemp, groupBy: "Category" };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      await commonPaginationForPost(
        getState,
        paramsTemp,
        params,
        dispatch,
        successGroupByCategory,
        "insightReasonCodeHeatMapFilterCategory",
        "GET_INSIGHTS_REASON_CODE_HEAT_MAP",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
const groupByDepartmentCall =
  (paramsTemp, params) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilterDepartment;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      params = { ...params, groupBy: "Department" };
      paramsTemp = { ...paramsTemp, groupBy: "Department" };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      await commonPaginationForPost(
        getState,
        paramsTemp,
        params,
        dispatch,
        successGroupByDepartment,
        "insightReasonCodeHeatMapFilterDepartment",
        "GET_INSIGHTS_REASON_CODE_HEAT_MAP",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
const groupByMissedOppCall =
  (paramsTemp, params) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilterDepartment;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      params = { ...params, groupBy: "missedOpp" };
      paramsTemp = { ...paramsTemp, groupBy: "missedOpp" };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPaginationForPost(
        getState,
        paramsTemp,
        params,
        dispatch,
        successGroupByMissedOpp,
        "insightReasonCodeHeatMapFilterAllMissedOpp",
        "GET_INSIGHTS_REASON_CODE_HEAT_MAP",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
const groupByCodeCall = (paramsTemp, params) => async (dispatch, getState) => {
  let sliceState =
    getState().npReducers.filters.insightReasonCodeHeatMapFilterDepartment;

  let start = sliceState.pageNumber;
  let limit = sliceState.pageSize;

  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    // start = rec_val + 1;
    start = rec_val;
  }
  if (start == 1) {
    start = 0;
  }

  try {
    params = { ...params, groupBy: "groupcode" };
    paramsTemp = { ...paramsTemp, groupBy: "groupcode" };

    // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

    await commonPaginationForPost(
      getState,
      paramsTemp,
      params,
      dispatch,
      successGroupByCode,
      "insightReasonCodeHeatMapFilterGroupCode",
      "GET_INSIGHTS_REASON_CODE_HEAT_MAP",
      null,
      "",
      ""
    );
  } catch (e) {
    // console.log("catch--->>", e);
  }
};

export const commonCustomSearchCPTHandlerDenialsHeatmap =
  (searchVal, setSearchLoading, listingFilter) =>
  async (dispatch, getState) => {
    // console.log(
    //   "commonCustomSearchCPTHandler_commonSlicer--->>",
    //   listingFilter
    // );
    let sliceState = getState().npReducers.filters[listingFilter];

    try {
      const cptParams = {
        CPTCode: searchVal ? searchVal : "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          ),
        }),
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      };
      let res = await genericAxiosCall(
        serverRoutes?.GET_INSIGHTS_REASON_CODE_HEAT_MAP_FILTERS,
        "post",
        cptParams,
        ""
      )
        .then((res) => {
          if (res?.data?.success) {
            const data = {
              data: res?.data?.data,
            };
            // const data = res?.data?.data;
            if (searchVal === "") {
              dispatch(successCptCodeFilter(data));
            } else {
              dispatch(successCptCodeFilter2(data));
            }
            setSearchLoading(false);
            return data;
          } else {
            if (searchVal === "") {
              dispatch(successCptCodeFilter([]));
            } else {
              dispatch(successCptCodeFilter2([]));
            }
          }
        })
        .catch((error) => {
          if (searchVal === "") {
            dispatch(successCptCodeFilter([]));
          } else {
            dispatch(successCptCodeFilter2([]));
          }
        });
      return res;
    } catch (e) {
      // console.log("commonCustomSearchCPTHandler_e--->>", e);
    }
  };
export const gettingInsightReasonCodeHeatmap_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "insightReasonCodeHeatMapFilter",
          ignore: [
            "selDeptHeatMapFilter",
            "selFacilitiesArr",
            "selHospitalsArr",
            "cptCodeFilter",
          ],
        })
      );

      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      let data = advStngServiceLineExecList?.split(",")?.map((item) => {
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      dispatch(
        setListingState({
          listing: "insightReasonCodeHeatMapFilter",
          value: {
            smartFilterArr: data,
            pageNumber: 1,
          },
        })
      );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilter;
    let sliceStateUser = getState().pReducers.user;
    const sliceStateFilterArr = getState().npReducers.carcTrendsDash;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          healthSystemId: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payers?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payers
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),
        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          healthSystemId: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payers?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payers
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),

        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };
      if (sliceState?.selDeptHeatMapFilter?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.selDeptHeatMapFilter
        );
        if (treeSelected) {
          params = {
            ...params,
            ...treeSelected,
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
          paramsTemp = {
            ...paramsTemp,
            ...treeSelected,

            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
        }
      }
      // console.log("api_call--->>", params);
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      dispatch(gettingDenialsHeatmapFilters(paramsTemp, params));
      if (sliceState?.groupByHeatMapFilter === "Remark Code") {
        await dispatch(groupByRemarkCodeCall(paramsTemp, params));
        dispatch(groupByAllCall(paramsTemp, params));
        dispatch(groupByCategoryCall(paramsTemp, params));
        dispatch(groupByDepartmentCall(paramsTemp, params));
        dispatch(groupByCodeCall(paramsTemp, params));
        dispatch(groupByMissedOppCall(paramsTemp, params));
      } else {
        let groupByType =
          sliceState?.groupByHeatMapFilter === "Procedure"
            ? "groupByCPTFilter"
            : "groupByCARCFilter";
        switch (sliceState[groupByType]) {
          case denialsHeatmapGroupByEnum?.AllActual || "All":
            await dispatch(groupByAllCall(paramsTemp, params));
            dispatch(groupByCategoryCall(paramsTemp, params));
            dispatch(groupByDepartmentCall(paramsTemp, params));
            dispatch(groupByCodeCall(paramsTemp, params));
            dispatch(groupByMissedOppCall(paramsTemp, params));
            dispatch(groupByRemarkCodeCall(paramsTemp, params));

            break;
          case "Department":
            await dispatch(groupByDepartmentCall(paramsTemp, params));
            dispatch(groupByAllCall(paramsTemp, params));
            dispatch(groupByCategoryCall(paramsTemp, params));
            dispatch(groupByCodeCall(paramsTemp, params));
            dispatch(groupByMissedOppCall(paramsTemp, params));
            dispatch(groupByRemarkCodeCall(paramsTemp, params));

            break;
          case "Categories":
            await dispatch(groupByCategoryCall(paramsTemp, params));
            dispatch(groupByAllCall(paramsTemp, params));
            dispatch(groupByDepartmentCall(paramsTemp, params));
            dispatch(groupByCodeCall(paramsTemp, params));
            dispatch(groupByMissedOppCall(paramsTemp, params));
            dispatch(groupByRemarkCodeCall(paramsTemp, params));

            break;
          case "Group Code":
            await dispatch(groupByCodeCall(paramsTemp, params));
            dispatch(groupByAllCall(paramsTemp, params));
            dispatch(groupByCategoryCall(paramsTemp, params));
            dispatch(groupByDepartmentCall(paramsTemp, params));
            dispatch(groupByMissedOppCall(paramsTemp, params));
            dispatch(groupByRemarkCodeCall(paramsTemp, params));

            break;
          case denialsHeatmapGroupByEnum?.AllMissedOpp:
            await dispatch(groupByMissedOppCall(paramsTemp, params));
            dispatch(groupByCodeCall(paramsTemp, params));
            dispatch(groupByAllCall(paramsTemp, params));
            dispatch(groupByCategoryCall(paramsTemp, params));
            dispatch(groupByDepartmentCall(paramsTemp, params));
            dispatch(groupByRemarkCodeCall(paramsTemp, params));

            break;

          default:
            break;
        }
      }
    } catch (e) {
      console.log("catch--->>", e);
    }
  };

// get filters
export const gettingSmartInsightReasonCodeHeatmap_Call =
  (target) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilter;

    let filterArr = sliceState?.smartFilterArr;

    try {
      dispatch(
        setListingState({
          listing: "insightReasonCodeHeatMapFilter",
          value: {
            loadingSmartFilter: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_SERVICE_LINE_EXECPTIONS,
        "get"
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        dispatch(
          setListingState({
            listing: "insightReasonCodeHeatMapFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );
        dispatch(setInsightsSmartFilters([]));
      } else {
        dispatch(
          setListingState({
            listing: "insightReasonCodeHeatMapFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );

        dispatch(setInsightsSmartFilters(res?.data?.data));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: target,
          value: {
            loadingSmartFilter: "",
          },
        })
      );
      return false;
    }
  };

// ----------------------------------------------------------------- Dep CARC
export const gettingDepartmentsCarcReasonCodeHeatmap_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    // const sliceStateForClient = await getState().npReducers.clientsConfig;

    if (clear) {
      dispatch(gettingSmartDepartmentByCarcReasonCodeHeatmap_Call());

      dispatch(
        resetListingState({
          listing: "departmentByCarcDashboardFilter",
          ignore: [
            "selDeptHeatMapFilter",
            "selFacilitiesArr",
            "selHospitalsArr",
            "cptCodeFilter",
          ],
        })
      );
      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      let data = advStngServiceLineExecList?.split(",")?.map((item) => {
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      dispatch(
        setListingState({
          listing: "departmentByCarcDashboardFilter",
          value: {
            smartFilterArr: data,
            pageNumber: 1,
          },
        })
      );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState =
      getState().npReducers.filters.departmentByCarcDashboardFilter;
    let sliceStateUser = getState().pReducers.user;
    const sliceStateFilterArr = getState().npReducers.carcTrendsDash;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(setDepCarcLoaders(true));

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospital: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),
        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospitals: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),

        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };
      if (sliceState?.selDeptHeatMapFilter?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.selDeptHeatMapFilter
        );
        if (treeSelected) {
          params = {
            ...params,
            ...treeSelected,
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
          paramsTemp = {
            ...paramsTemp,
            ...treeSelected,

            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
        }
      }

      let res = await genericAxiosCall(
        serverRoutes?.GET_TOP_TEN_CARC_FOR_TABLE,
        "get",
        "",
        params
      );

      // dispatch(setDepCarcTableData(res?.data?.data));

      let resTreeMap = await genericAxiosCall(
        serverRoutes?.GET_TOP_TEN_CARC_FOR_TREE_MAP,
        "get",
        "",
        params
      );

      let resTest = await genericAxiosCall(
        serverRoutes?.GET_CARC_HEADER_DEP,
        "get",
        "",
        params
      );
      dispatch(setDepCarcTableData(resTest?.data?.data));

      dispatch(setDepCarcTreeMapData(resTreeMap?.data?.data));
      dispatch(gettingDenialsHeatmapFilters(paramsTemp, params));
    } catch (e) {
      console.log(">> catch", e);
    }
  };

// ----------------------------------------------------------------- Dep CARC Table level 2 data
export const gettingDepartmentsCarcTableLevel2_Call =
  (id) => async (dispatch, getState) => {
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState =
      getState().npReducers.filters.departmentByCarcDashboardFilter;
    let sliceStateUser = getState().pReducers.user;
    const sliceStateFilterArr = getState().npReducers.carcTrendsDash;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospital: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),
        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospitals: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),

        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };
      if (sliceState?.selDeptHeatMapFilter?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.selDeptHeatMapFilter
        );
        if (treeSelected) {
          params = {
            ...params,
            ...treeSelected,
            ...(sliceState?.smartFilter &&
              sliceState?.smartFilterArr?.length > 0 && {
                SmartFilter: getMultiSelectFilterValueForApi(
                  sliceState?.smartFilterArr
                )?.toString(),
              }),
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
          paramsTemp = {
            ...paramsTemp,
            ...treeSelected,
            ...(sliceState?.smartFilter &&
              sliceState?.smartFilterArr?.length > 0 && {
                SmartFilter: getMultiSelectFilterValueForApi(
                  sliceState?.smartFilterArr
                )?.toString(),
              }),
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
        }
      }
      const paramsNew = { ...params, level0: id };
      let resTest2 = await genericAxiosCall(
        serverRoutes?.GET_CARC_DEP_TABLE,
        "get",
        "",
        paramsNew
      );
      return resTest2;
    } catch (e) {
      console.log(">> catch", e);
    }
  };

// ----------------------------------------------------------------- Dep RARC Table level 2 data
export const gettingDepartmentsRarcTableLevel2_Call =
  (id) => async (dispatch, getState) => {
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState =
      getState().npReducers.filters.departmentByRarcDashboardFilter;
    let sliceStateUser = getState().pReducers.user;
    const sliceStateFilterArr = getState().npReducers.carcTrendsDash;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospital: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),
        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospitals: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),

        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };
      if (sliceState?.selDeptHeatMapFilter?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.selDeptHeatMapFilter
        );
        if (treeSelected) {
          params = {
            ...params,
            ...treeSelected,
            ...(sliceState?.smartFilter &&
              sliceState?.smartFilterArr?.length > 0 && {
                SmartFilter: getMultiSelectFilterValueForApi(
                  sliceState?.smartFilterArr
                )?.toString(),
              }),
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
          paramsTemp = {
            ...paramsTemp,
            ...treeSelected,
            ...(sliceState?.smartFilter &&
              sliceState?.smartFilterArr?.length > 0 && {
                SmartFilter: getMultiSelectFilterValueForApi(
                  sliceState?.smartFilterArr
                )?.toString(),
              }),
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
        }
      }
      const paramsNew = { ...params, level0: id };
      let resTest2 = await genericAxiosCall(
        serverRoutes?.GET_RARC_DEP_TABLE,
        "get",
        "",
        paramsNew
      );
      return resTest2;
    } catch (e) {
      console.log(">> catch", e);
    }
  };

// ----------------------------------------------------------------- Dep RARC Table level 2 data
export const gettingDepartmentsCptTableLevel2_Call =
  (id) => async (dispatch, getState) => {
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState =
      getState().npReducers.filters.departmentByCptDashboardFilter;
    let sliceStateUser = getState().pReducers.user;
    const sliceStateFilterArr = getState().npReducers.carcTrendsDash;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospital: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),
        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospitals: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),

        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };
      if (sliceState?.selDeptHeatMapFilter?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.selDeptHeatMapFilter
        );
        if (treeSelected) {
          params = {
            ...params,
            ...treeSelected,
            ...(sliceState?.smartFilter &&
              sliceState?.smartFilterArr?.length > 0 && {
                SmartFilter: getMultiSelectFilterValueForApi(
                  sliceState?.smartFilterArr
                )?.toString(),
              }),
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
          paramsTemp = {
            ...paramsTemp,
            ...treeSelected,
            ...(sliceState?.smartFilter &&
              sliceState?.smartFilterArr?.length > 0 && {
                SmartFilter: getMultiSelectFilterValueForApi(
                  sliceState?.smartFilterArr
                )?.toString(),
              }),
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
        }
      }
      const paramsNew = { ...params, level0: id };
      let resTest2 = await genericAxiosCall(
        serverRoutes?.GET_CPT_DEP_TABLE,
        "get",
        "",
        paramsNew
      );
      return resTest2;
    } catch (e) {
      console.log(">> catch", e);
    }
  };

// get filters
export const gettingSmartDepartmentByCarcReasonCodeHeatmap_Call =
  (target) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.departmentByCarcDashboardFilter;

    let filterArr = sliceState?.smartFilterArr;

    try {
      dispatch(
        setListingState({
          listing: "departmentByCarcDashboardFilter",
          value: {
            loadingSmartFilter: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_SERVICE_LINE_EXECPTIONS,
        "get"
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        dispatch(
          setListingState({
            listing: "departmentByCarcDashboardFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );
        dispatch(setInsightsSmartFilters([]));
      } else {
        dispatch(
          setListingState({
            listing: "departmentByCarcDashboardFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );

        dispatch(setInsightsSmartFilters(res?.data?.data));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: target,
          value: {
            loadingSmartFilter: "",
          },
        })
      );
      return false;
    }
  };

// ----------------------------------------------------------------- Dep CARC

// ----------------------------------------------------------------- Dep CPT
export const gettingDepartmentsCptReasonCodeHeatmap_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    const sliceStateForClient = await getState().npReducers.clientsConfig;

    if (clear) {
      dispatch(
        resetListingState({
          listing: "departmentByCptDashboardFilter",
          ignore: [
            "selDeptHeatMapFilter",
            "selFacilitiesArr",
            "selHospitalsArr",
            "cptCodeFilter",
          ],
        })
      );

      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      let data = advStngServiceLineExecList?.split(",")?.map((item) => {
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      dispatch(
        setListingState({
          listing: "departmentByCptDashboardFilter",
          value: {
            smartFilterArr: data,
            pageNumber: 1,
          },
        })
      );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }
    dispatch(setDepCptLoaders(true));

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState =
      getState().npReducers.filters.departmentByCptDashboardFilter;
    let sliceStateUser = getState().pReducers.user;
    const sliceStateFilterArr = getState().npReducers.carcTrendsDash;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(gettingSmartDepartmentByCptReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospital: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),
        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospitals: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),

        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      if (sliceState?.selDeptHeatMapFilter?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.selDeptHeatMapFilter
        );
        if (treeSelected) {
          params = {
            ...params,
            ...treeSelected,
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
          paramsTemp = {
            ...paramsTemp,
            ...treeSelected,

            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
        }
        // if (treeSelected) {
        //   params = { ...params, ...treeSelected };
        //   paramsTemp = { ...paramsTemp, ...treeSelected };
        // }
      }
      let res = await genericAxiosCall(
        serverRoutes?.GET_TOP_TEN_CPT_FOR_TABLE,
        "get",
        "",
        params
      );
      // console.log("res >> ", res);
      // dispatch(setDepCptTableData(res?.data?.data));

      let resTreeMap = await genericAxiosCall(
        serverRoutes?.GET_TOP_TEN_CPT_FOR_TREE_MAP,
        "get",
        "",
        params
      );

      let resTest2 = await genericAxiosCall(
        serverRoutes?.GET_CPT_HEADER_DEP,
        "get",
        "",
        params
      );
      dispatch(setDepCptTableData(resTest2?.data?.data));

      // console.log("123123 res >> ", resTest2);
      dispatch(setDepCptTreeMapData(resTreeMap?.data?.data));
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      dispatch(gettingDenialsHeatmapFilters(paramsTemp, params));
      // if (sliceState?.groupByHeatMapFilter === "Remark Code") {
      //   await dispatch(groupByRemarkCodeCall(paramsTemp, params));
      //   dispatch(groupByAllCall(paramsTemp, params));
      //   dispatch(groupByCategoryCall(paramsTemp, params));
      //   dispatch(groupByDepartmentCall(paramsTemp, params));
      //   dispatch(groupByCodeCall(paramsTemp, params));
      //   dispatch(groupByMissedOppCall(paramsTemp, params));
      // } else {
      //   let groupByType =
      //     sliceState?.groupByHeatMapFilter === "Procedure"
      //       ? "groupByCPTFilter"
      //       : "groupByCARCFilter";
      //   switch (sliceState[groupByType]) {
      //     case denialsHeatmapGroupByEnum?.AllActual || "All":
      //       await dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;
      //     case "Department":
      //       await dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;
      //     case "Categories":
      //       await dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;
      //     case "Group Code":
      //       await dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;
      //     case denialsHeatmapGroupByEnum?.AllMissedOpp:
      //       await dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;

      //     default:
      //       break;
      //   }
      // }
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };

// get filters
export const gettingSmartDepartmentByCptReasonCodeHeatmap_Call =
  (target) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.departmentByCarcDashboardFilter;

    let filterArr = sliceState?.smartFilterArr;

    try {
      dispatch(
        setListingState({
          listing: "departmentByCptDashboardFilter",
          value: {
            loadingSmartFilter: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_SERVICE_LINE_EXECPTIONS,
        "get"
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        dispatch(
          setListingState({
            listing: "departmentByCptDashboardFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );
        dispatch(setInsightsSmartFilters([]));
      } else {
        dispatch(
          setListingState({
            listing: "departmentByCptDashboardFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );

        dispatch(setInsightsSmartFilters(res?.data?.data));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: target,
          value: {
            loadingSmartFilter: "",
          },
        })
      );
      return false;
    }
  };

// ----------------------------------------------------------------- Dep CPT

// ----------------------------------------------------------------- Dep RARC
export const gettingDepartmentsRarcReasonCodeHeatmap_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    await gettingClientsConfigListForDropdown();
    const sliceStateForClient = await getState().npReducers.clientsConfig;
    // if (typeof sliceStateForClient?.selectedClientIds == typeof 2) {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "departmentByRarcDashboardFilter",
          ignore: [
            "selDeptHeatMapFilter",
            "selFacilitiesArr",
            "selHospitalsArr",
            "cptCodeFilter",
          ],
        })
      );

      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      let data = advStngServiceLineExecList?.split(",")?.map((item) => {
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      dispatch(
        setListingState({
          listing: "departmentByRarcDashboardFilter",
          value: {
            smartFilterArr: data,
            pageNumber: 1,
          },
        })
      );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }
    dispatch(setDepRarcLoaders(true));

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState =
      getState().npReducers.filters.departmentByRarcDashboardFilter;
    let sliceStateUser = getState().pReducers.user;
    const sliceStateFilterArr = getState().npReducers.carcTrendsDash;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(gettingSmartDepartmentByRarcReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospital: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),
        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),

        ...(sliceState?.cptCodeFilter?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.cptCodeFilter?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceState?.cptCodeFilter
          )?.toString(),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospitals: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            )?.toString(),
          }),

        ...(sliceState?.claimStatusFilter &&
          sliceState?.claimStatusIds?.length > 0 && {
            ClaimStatusIds: exceptionArray(
              sliceState?.claimStatusIds,
              sliceStateFilterArr?.claimStatus23FilterArr
            )?.toString(),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      if (sliceState?.selDeptHeatMapFilter?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.selDeptHeatMapFilter
        );
        if (treeSelected) {
          params = {
            ...params,
            ...treeSelected,
            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
          paramsTemp = {
            ...paramsTemp,
            ...treeSelected,

            ...(sliceState?.selFacilitiesArr?.length > 0 && {
              Facility: getMultiSelectFilterValueForApi(
                sliceState?.selFacilitiesArr
              )?.toString(),
            }),
          };
        }
        // if (treeSelected) {
        //   params = { ...params, ...treeSelected };
        //   paramsTemp = { ...paramsTemp, ...treeSelected };
        // }
      }
      let res = await genericAxiosCall(
        serverRoutes?.GET_TOP_TEN_RARC_FOR_TABLE,
        "get",
        "",
        params
      );
      // console.log("res >> ", res);
      // dispatch(setDepRarcTableData(res?.data?.data));

      let resTreeMap = await genericAxiosCall(
        serverRoutes?.GET_TOP_TEN_RARC_FOR_TREE_MAP,
        "get",
        "",
        params
      );
      dispatch(setDepRarcTreeMapData(resTreeMap?.data?.data));

      let resTest = await genericAxiosCall(
        serverRoutes?.GET_RARC_HEADER_DEP,
        "get",
        "",
        params
      );
      dispatch(setDepRarcTableData(resTest?.data?.data));

      // console.log("123123 res >> ", resTest);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      dispatch(gettingDenialsHeatmapFilters(paramsTemp, params));
      // if (sliceState?.groupByHeatMapFilter === "Remark Code") {
      //   await dispatch(groupByRemarkCodeCall(paramsTemp, params));
      //   dispatch(groupByAllCall(paramsTemp, params));
      //   dispatch(groupByCategoryCall(paramsTemp, params));
      //   dispatch(groupByDepartmentCall(paramsTemp, params));
      //   dispatch(groupByCodeCall(paramsTemp, params));
      //   dispatch(groupByMissedOppCall(paramsTemp, params));
      // } else {
      //   let groupByType =
      //     sliceState?.groupByHeatMapFilter === "Procedure"
      //       ? "groupByCPTFilter"
      //       : "groupByCARCFilter";
      //   switch (sliceState[groupByType]) {
      //     case denialsHeatmapGroupByEnum?.AllActual || "All":
      //       await dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;
      //     case "Department":
      //       await dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;
      //     case "Categories":
      //       await dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;
      //     case "Group Code":
      //       await dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;
      //     case denialsHeatmapGroupByEnum?.AllMissedOpp:
      //       await dispatch(groupByMissedOppCall(paramsTemp, params));
      //       dispatch(groupByCodeCall(paramsTemp, params));
      //       dispatch(groupByAllCall(paramsTemp, params));
      //       dispatch(groupByCategoryCall(paramsTemp, params));
      //       dispatch(groupByDepartmentCall(paramsTemp, params));
      //       dispatch(groupByRemarkCodeCall(paramsTemp, params));

      //       break;

      //     default:
      //       break;
      //   }
      // }
    } catch (e) {
      // console.log("catch--->>", e);
    }
    // }
  };

// get filters
export const gettingSmartDepartmentByRarcReasonCodeHeatmap_Call =
  (target) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.departmentByCarcDashboardFilter;

    let filterArr = sliceState?.smartFilterArr;

    try {
      dispatch(
        setListingState({
          listing: "departmentByRarcDashboardFilter",
          value: {
            loadingSmartFilter: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_SERVICE_LINE_EXECPTIONS,
        "get"
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        dispatch(
          setListingState({
            listing: "departmentByRarcDashboardFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );
        dispatch(setInsightsSmartFilters([]));
      } else {
        dispatch(
          setListingState({
            listing: "departmentByRarcDashboardFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );

        dispatch(setInsightsSmartFilters(res?.data?.data));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: target,
          value: {
            loadingSmartFilter: "",
          },
        })
      );
      return false;
    }
  };

// ----------------------------------------------------------------- Dep CPT

const cptCodeListInitial = (state, action) => {
  const data = action?.payload;

  const cptCodes = data?.cptCodes;
  const selectedCptCodes = data?.selectedCodes;
  // console.log("successCptCodeFilter--->>", data);
  // console.log("successCptCodeFilter__if--->>", data);

  if (cptCodes?.length) {
    if (selectedCptCodes?.length > 0) {
      const excludedValues = selectedCptCodes.map((obj) => obj?.cptCode);
      const excludedArray = selectedCptCodes.map((obj) => {
        let userFriendlyName = obj?.description.length
          ? `${obj?.cptCode} - ${obj?.description}`
          : `${obj?.cptCode}`;

        return {
          value: obj?.cptCode,
          label: userFriendlyName,
        };
      });

      let arrTemp = [];
      let newArr1 = [];
      cptCodes?.filter((obj) => {
        if (excludedValues.includes(obj?.cptCode)) {
          arrTemp.push(obj);
        } else {
          let userFriendlyName = obj?.description.length
            ? `${obj?.cptCode} - ${obj?.description}`
            : `${obj?.cptCode}`;
          newArr1.push({
            value: obj?.cptCode,
            label: userFriendlyName,
          });
        }
      });
      newArr1.unshift(...excludedArray);

      state.cptCodeList = newArr1;
    } else {
      let newArr = [];

      cptCodes?.forEach((item) => {
        let userFriendlyName = item?.description.length
          ? `${item?.cptCode} - ${item?.description}`
          : `${item?.cptCode}`;
        newArr.push({
          value: item?.cptCode,

          label: userFriendlyName,
        });
      });

      state.cptCodeList = newArr;
    }
  } else {
    state.cptCodeList = [];
  }
};
export default OncologyCARCSlice.reducer;
